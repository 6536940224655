/*
-----------------------------------------------------------------------
	Home Slider CSS
-----------------------------------------------------------------------
*/
.home-slider-area {
  height: 100vh;
}

h1 {
  text-align: center;
}

.home-slider-area.slider-default {
  display: flex;
  height: 1000px;
}

@media only screen and (max-width: 1199px) {
  .home-slider-area.slider-default {
    height: 850px;
  }
}

@media only screen and (max-width: 991px) {
  .home-slider-area.slider-default {
    height: 780px;
  }
}

@media only screen and (max-width: 767px) {
  .home-slider-area.slider-default {
    height: 710px;
  }
}

@media only screen and (max-width: 575px) {
  .home-slider-area.slider-default {
    height: 620px;
  }
}

@media only screen and (max-width: 479.98px) {
  .home-slider-area.slider-default {
    height: 500px;
  }
}

@media (max-width: 461px) {
  .home-slider-area.slider-default {
    height: 100vh;
  }
}

.home-slider-area.slider-default .slider-content-area {
  margin-top: 328px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .home-slider-area.slider-default .slider-content-area {
    margin-top: 300px;
  }
}

@media only screen and (max-width: 991px) {
  .home-slider-area.slider-default .slider-content-area {
    margin-top: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .home-slider-area.slider-default .slider-content-area {
    margin-top: 240px;
  }
}

@media only screen and (max-width: 575px) {
  .home-slider-area.slider-default .slider-content-area {
    margin-top: 230px;
  }
}

@media only screen and (max-width: 479.98px) {
  .home-slider-area.slider-default .slider-content-area {
    /* margin-top: 200px; */
        /* margin-top: 82%; */
        margin-top: 46%;
  }
}

@media only screen and (max-width: 375px) {
  .home-slider-area.slider-default .slider-content-area {
    margin-top: 40%;
  }
}

.home-slider-area.slider-default .slider-content-area h5 {
  color: var(--white);
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.411px;
  line-height: 1;
  margin-bottom: 34px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1199px) {
  .home-slider-area.slider-default .slider-content-area h5 {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .home-slider-area.slider-default .slider-content-area h5 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479.98px) {
  .home-slider-area.slider-default .slider-content-area h5 {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.home-slider-area.slider-default .slider-content-area h2 {
  color: var(--white);
  font-size: 85px;
  font-weight: 800;
  letter-spacing: -2.04px;
  line-height: 100px;
  margin: 0 auto 10px;
  max-width: 886px;
}

@media only screen and (max-width: 1399px) {
  .home-slider-area.slider-default .slider-content-area h2 {
    font-size: 84px;
    line-height: 94px;
  }
}

@media only screen and (max-width: 1199px) {
  .home-slider-area.slider-default .slider-content-area h2 {
    font-size: 72px;
    line-height: 84px;
  }
}

@media only screen and (max-width: 767px) {
  .home-slider-area.slider-default .slider-content-area h2 {
    font-size: 64px;
    line-height: 72px;
  }
}

@media only screen and (max-width: 575px) {
  .home-slider-area.slider-default .slider-content-area h2 {
    font-size: 50px;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 479.98px) {
  .home-slider-area.slider-default .slider-content-area h2 {
    font-size: 40px;
    margin-top: 15%;
  }
}

.home-slider-area.slider-default .slider-content-area h2 span {
  font-weight: 300;
}