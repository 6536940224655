
/*
-----------------------------------------------------------------------
  inscription CSS
-----------------------------------------------------------------------
*/

.inscription .post-item {
    display: flex;
}

@media only screen and (max-width: 575px) {
    .inscription .post-item {
        display: block;
        margin: 2px;
    }
}

.inscription .post-item .content .title {
    font-weight: 200;
    line-height: 38px;
    letter-spacing: 1px;
    margin-bottom: 21px;
    text-align: justify;
    padding: 13px 3px 10px;
}

@media only screen and (max-width: 1199px) {
.inscription .post-item .content .title {
  font-size: 20px;
}
}

@media only screen and (max-width: 767px) {
.inscription .post-item .content .title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
}
}

/* Sous-Titres================================= */

.inscription .post-item .content .title a {
    font-size: 16px;
    line-height: 30px;
    color: var(--grey-alt);
    margin: 0;
    font-family: 'Lato', sans-serif;
}

.inscription .post-item .content .title ul{
    font-size: 16px;
    line-height: 30px;
    color: var(--grey-alt);
    margin: 0;
    font-family: 'Lato', sans-serif;
    margin-left: 49px;
    padding-top: 12px;
    line-height: 30px;
}

@media only screen and (max-width: 1199px){
    .inscription .post-item .content .title a {
    font-size: 16px;
    line-height: 30px;
    color: var(--grey-alt);
    margin: 0;
    font-family: 'Lato', sans-serif;
    }
}

@media only screen and (max-width: 767px){
    .inscription .post-item .content .title a {
    font-size: 16px;
    line-height: 30px;
    color: var(--grey-alt);
    margin: 0;
    font-family: 'Lato', sans-serif;
    }

    .inscription .post-item .content .title ul{
        font-size: 16px;
        line-height: 30px;
        color: var(--grey-alt);
        margin: 0;
        font-family: 'Lato', sans-serif;
        margin-left: 49px;
        padding-top: 12px;
        line-height: 30px;
    }
}

