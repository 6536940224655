/*-------- Scroll To Top Style Css --------*/
 .scroll-to-top {

    box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;

    background-color: var(--primary);
    border: none;
    border-radius: 0;
    bottom: 35px;
    color: var(--white);
    cursor: pointer;
    display: none;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 35px;
    text-align: center;
    width: 60px;
    z-index: 100;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 1199px) {
    .scroll-to-top {
        bottom: 27px;
        font-size: 14px;
        height: 40px;
        line-height: 35px;
        right: 20px;
        width: 40px;
       
    }
  }
  @media only screen and (max-width: 479.98px) {
    .scroll-to-top {
      bottom: 87px;
      right: 10px;
    }
  }
  .scroll-to-top:hover {
    background-color: #303744;
    box-shadow: none;
    color: var(--white);
  }
  /* ////////////============================================================= */

  .Whatsappwidget {
    background-color: var(--primary);
    border: none;
    border-radius: 0;
    bottom: 35px;
    color: var(--white);
    cursor: pointer;
    display: none;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 35px;
    text-align: center;
    width: 60px;
    z-index: 100;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  @media only screen and (max-width: 1199px) {
    .Whatsappwidget{
      bottom: 27px;
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      right: 20px;
      width: 35px;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .Whatsappwidget{
      bottom: 87px;
      right: 10px;
    }
  }
  
  .Whatsappwidget:hover {
    background-color: #303744;
    box-shadow: none;
    color: var(--white);
  }


  ._1bpcM {
    align-items: center;
    align-self: flex-end;
    background-color: rgb(255 255 255);
    border-radius:0%;
    box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
    color: rgb(224 36 23);
    cursor: pointer;
    display: flex;
    font-size: 2em;
    height: 60px;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: -31px;
    margin-left: 20px;
    outline: transparent;
    -webkit-user-select: none;
    user-select: none;
    width: 60px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    position: absolute;
    bottom: 52px;
    right: 30px;
  }

  ._2rVjT {
    background-color: var(--primary);
    display: flex;
  }

  ._2W95M {
    padding: 6px 12px;
    border-radius: 20px;
    border: none;
    background: var(--primary);
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    /* margin: 0 20px; */
    box-shadow: rgb(0 0 0 / 13%) 0px 1px 1px;
  }

  ._10MlI {
    align-items: center;
    align-self: center;
    background: #fff;
    border-radius: 50%;
    color: var(--primary);
    display: flex;
    font-size: 2em;
    height: 45px;
    margin: 5px;
    justify-content: center;
    padding: 10px;
    width: 45px;
  }

  ._3CMpi {
    font-size: 14px;
    line-height: 19px;
    margin-top: 4px;
    color: rgb(17, 17, 17);
    white-space: pre-wrap;
    text-align: left;
  }

  ._1w7Ch {
    color: #f0ffffa1;
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    right: 10px;

  }

  @media (min-width: 320px) and (max-width: 1199px){
    ._1bpcM {
      align-items: center;
      align-self: flex-end;
      /* background-color: rgb(255 255 255); */
      border-radius: 0%;
      box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
      color: rgb(224 36 23);
      cursor: pointer;
      display: flex;
      font-size: 2em;
      height: 40px;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: -31px;
      margin-left: 20px;
      outline: transparent;
      -webkit-user-select: none;
      user-select: none;
      width: 41px;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      position: absolute;
      bottom: 40px;
      right: 30px;
    }

    ._nYEjV {
      padding: 0px 14px 5px;
      background-color: rgb(255, 255, 255);
      border-radius: 0px 8px 8px;
      position: relative;
      transition: all 0.3s ease 0s;
      transform-origin: center top;
      box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
      max-width: calc(100% - 12px);
      text-align: left;
    }

    ._30mQ0 {
      background-color: #f0f0f0;
      display: flex;
      flex-direction: column;
      padding: 25px;
    }

    ._2iWL7 {
      width: 265px;
      box-shadow: 0 12px 24px 0 rgb(0 0 0 / 10%);
      flex-direction: column;
      border-radius: 15px;
      overflow: hidden;
      background-color: #fff;
      transform: translateZ(0);
      touch-action: auto;
      position: absolute;
      margin: 20px;
      bottom: 99px;
      right: 30px;
    }

    ._1w7Ch {
      color: #f0ffffa1;
      cursor: pointer;
      font-size: 1.5em;
      position: absolute;
      right: 10px;
    }


  }

