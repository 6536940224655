
/*
-----------------------------------------------------------------------
	temoignage CSS
-----------------------------------------------------------------------
*/
.temoignage-area.temoignage-default-area {
    padding: 145px 0 150px;
  }
  @media only screen and (max-width: 1199px) {
    .temoignage-area.temoignage-default-area {
      padding: 98px 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .temoignage-area.temoignage-default-area {
      padding: 78px 0;
    }
  }
  @media only screen and (max-width: 575px) {
    .temoignage-area.temoignage-default-area {
      padding: 68px 0;
    }
  }
  .temoignage-area.temoignage-default-area .section-title {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 1199px) {
    .temoignage-area.temoignage-default-area .section-title {
      margin-bottom: 75px;
    }
  }
  @media only screen and (max-width: 991px) {
    .temoignage-area.temoignage-default-area .section-title {
      margin-bottom: 60px;
    }
  }
  @media only screen and (max-width: 575px) {
    .temoignage-area.temoignage-default-area .section-title {
      margin-bottom: 50px;
    }
  }
  
  .temoignage-item {
    padding: 0;
    position: relative;
    text-align: center;
  }
  .temoignage-item .client-content {
    margin: 0 auto 65px;
    max-width: 838px;
    position: relative;
    z-index: 1;
  }
  @media only screen and (max-width: 1199px) {
    .temoignage-item .client-content {
      margin-bottom: 45px;
    }
  }
  .temoignage-item .client-content p {
    color: var(--black);
    /* font-family: "Libre Baskerville", serif; */
    font-size: 30px;
    line-height: 48px;
    letter-spacing: -0.7px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1199px) {
    .temoignage-item .client-content p {
      font-size: 24px;
      line-height: 38px;
    }
    .temoignage-item .client-content p br {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .temoignage-item .client-content p {
      font-size: 16px;
      line-height: 32px;
    }
  }
  .temoignage-item .thumb {
    border-radius: 50%;
    margin-bottom: 50px;
    display: inline-flex;
  }
  .temoignage-item .thumb img {
    border-radius: 50%;
  }
  .temoignage-item .client-info {
    margin-bottom: 35px;
    position: relative;
  }
  .temoignage-item .client-info .name {
    color: var(--primary);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.25px;
    margin-bottom: 6px;
  }
  @media only screen and (max-width: 767px) {
    .temoignage-item .client-info .name {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .temoignage-item .client-info .client-location {
    color: var(--grey2);
    font-size: 13px;
    letter-spacing: 0;
  }
  .temoignage-item .client-info .icon-quote {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
  }
  .temoignage-item .client-info .icon-quote img {
    width: auto;
  }
  @media only screen and (max-width: 479.98px) {
    .temoignage-item .client-info .icon-quote img {
      display: none;
    }
  }
  .temoignage-item.line-top {
    border-top: 4px solid var(--primary);
  }
  
  .temoignage-slider-container .swiper-button-next,
  .temoignage-slider-container .swiper-button-prev {
    color: var(--black);
    font-size: 60px;
    top: 41.5%;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 991px) {
    .temoignage-slider-container .swiper-button-next,
  .temoignage-slider-container .swiper-button-prev {
      top: 28%;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .temoignage-slider-container .swiper-button-next,
  .temoignage-slider-container .swiper-button-prev {
      top: 18%;
    }
  }
  .temoignage-slider-container .swiper-button-next:after,
  .temoignage-slider-container .swiper-button-prev:after {
    color: var(--black);
    content: "";
    font-family: "IcoFont";
    font-size: 60px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 767px) {
    .temoignage-slider-container .swiper-button-next:after,
  .temoignage-slider-container .swiper-button-prev:after {
      font-size: 36px;
    }
  }
  .temoignage-slider-container .swiper-button-next:hover:after,
  .temoignage-slider-container .swiper-button-prev:hover:after {
    color: var(--primary);
  }
  .temoignage-slider-container .swiper-button-prev {
    left: 5px;
  }
  @media only screen and (max-width: 991px) {
    .temoignage-slider-container .swiper-button-prev {
      left: 0;
    }
  }
  .temoignage-slider-container .swiper-button-next {
    right: 5px;
  }
  @media only screen and (max-width: 991px) {
    .temoignage-slider-container .swiper-button-next {
      right: 0;
    }
  }
  .temoignage-slider-container .swiper-button-next:after {
    content: "";
  }
  