/*
-----------------------------------------------------------------------
	Blog CSS
-----------------------------------------------------------------------
*/
.blog-area.blog-default-area {
  padding: 130px 0 120px;
}
@media only screen and (max-width: 1199px) {
  .blog-area.blog-default-area {
    padding: 100px 0 95px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-area.blog-default-area {
    padding: 65px 0 80px;
  }
}
@media only screen and (max-width: 575px) {
  .blog-area.blog-default-area {
    padding: 45px 0 70px;
  }
}
.blog-area.blog-default-area .section-title {
  margin-bottom: 70px;
}
@media only screen and (max-width: 991px) {
  .blog-area.blog-default-area .section-title {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .blog-area.blog-default-area .section-title {
    margin-bottom: 40px;
  }
}

.post-items-style1 {
  padding-right: 69px;
}
@media only screen and (max-width: 1199px) {
  .post-items-style1 {
    padding-right: 10px;
  }
}
.post-items-style1 .post-item {
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);
  margin-bottom: 27px;
  padding-bottom: 24px;
}
@media only screen and (max-width: 1199px) {
  .post-items-style1 .post-item {
    display: flex;
  }
}
@media only screen and (max-width: 479.98px) {
  .post-items-style1 .post-item {
    display: block;
  }
}
.post-items-style1 .post-item .thumb {
  float: left;
  min-width: 138px;
  margin-right: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 479.98px) {
  .post-items-style1 .post-item .thumb {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.post-items-style1 .post-item .thumb img {
  width: 100%;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
}
.post-items-style1 .post-item .content .title {
  color: #000;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
  margin-bottom: 7px;
  position: relative;
  top: -5px;
}
@media only screen and (max-width: 575px) {
  .post-items-style1 .post-item .content .title {
    font-size: 16px;
  }
}
.post-items-style1 .post-item .content .title a {
  color: #000;
}
@media only screen and (max-width: 991px) {
  .post-items-style1 .post-item .content .title a br {
    display: none;
  }
}
.post-items-style1 .post-item .content .title a:hover {
  color: var(--primary);
}
.post-items-style1 .post-item .content .category {
  color: #666666;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 14px;
}
.post-items-style1 .post-item .content .meta {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.post-items-style1 .post-item .content .meta a {
  color: #000;
}
.post-items-style1 .post-item .content .meta a .icon {
  color: #666666;
  padding-right: 7px;
}
.post-items-style1 .post-item .content .meta a:hover {
  color: var(--primary);
}
.post-items-style1 .post-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.post-items-style1 .post-item:hover .thumb img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.post-items-style2 .post-item {
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .post-items-style2 .post-item {
    display: block;
  }
}
.post-items-style2 .post-item .thumb {
  float: left;
  min-width: 300px;
  margin-right: 26px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 575px) {
  .post-items-style2 .post-item .thumb {
    float: none;
    min-width: auto;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.post-items-style2 .post-item .thumb img { 
  width: 312px;
  /* height: 190px; */
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
}

.post-items-style2 .post-item .content .title {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
  margin-bottom: 21px;
  text-align: justify;
}
@media only screen and (max-width: 1199px) {
  .post-items-style2 .post-item .content .title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .post-items-style2 .post-item .content .title {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 10px;
  }

  .post-items-style2 .post-item .thumb img {
    width: 100%;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
  }

}
.post-items-style2 .post-item .content .title a {
  color: var(--grey3);
}
.post-items-style2 .post-item .content .title a:hover {
  color:var(--grey3);
}

.post-items-style2 .post-item .content .category {
  color: rgb(255, 255, 255);
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 9px;
  position: relative;
  text-transform: uppercase;
  top: -7px;
}

.post-items-style2 .post-item .content .meta {
  color: #000;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: -0.15px;
  margin-bottom: 0;
}
.post-items-style2 .post-item .content .meta a {
  color: #000;
}
.post-items-style2 .post-item .content .meta a .icon {
  color: #666666;
  padding-right: 7px;
}
.post-items-style2 .post-item .content .meta a:hover {
  color: #e11717;
}
.post-items-style2 .post-item:last-child {
  /* border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0; */
}
.post-items-style2 .post-item:hover .thumb img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.post-items-style3 .post-item .thumb {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.post-items-style3 .post-item .thumb img {
  width: 100%;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
}
.post-items-style3 .post-item .content .title {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
  margin-bottom: 7px;
}
.post-items-style3 .post-item .content .title a {
  color: #000;
}
.post-items-style3 .post-item .content .title a:hover {
  color: var(--primary);
}
.post-items-style3 .post-item .content .category {
  color: var(--primary);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 11px;
  text-transform: uppercase;
}
.post-items-style3 .post-item .content .meta {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.post-items-style3 .post-item .content .meta a {
  color: #000;
}
.post-items-style3 .post-item .content .meta a .icon {
  color: #666666;
  padding-right: 7px;
}
.post-items-style3 .post-item .content .meta a:hover {
  color: var(--primary);
}
.post-items-style3 .post-item:hover .thumb img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.post-details-content .post-details-body .content .title {
  color: #000;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 65px;
  margin-right: 100px;
}
@media only screen and (max-width: 767px) {
  .post-details-content .post-details-body .content .title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .post-details-content .post-details-body .content .title {
    margin-right: 0;
  }
}
.post-details-content .post-details-body .content .meta {
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.15px;
  margin-bottom: 37px;
  margin-top: 42px;
}
.post-details-content .post-details-body .content .meta .category {
  color: var(--primary);
  font-size: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
}
.post-details-content .post-details-body .content .meta span {
  display: inline-block;
  margin: 0 6px;
}
.post-details-content .post-details-body .content .meta .author {
  color: #000;
}
.post-details-content .post-details-body .content .meta .author:hover {
  color: var(--primary);
}
.post-details-content .post-details-body .content p {
  color: #666666;
  font-size: 16px;
  letter-spacing: -0.15px;
  line-height: 30px;
  margin-bottom: 54px;
}
.post-details-content .post-details-body .content h4 {
  font-size: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 34px;
  margin-top: 69px;
}
@media only screen and (max-width: 767px) {
  .post-details-content .post-details-body .content h4 {
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 50px;
  }
}

.post-details-content .post-details-body .content .blockquote-area {
  padding: 14px 145px 0px 58px;
}

@media only screen and (max-width: 767px) {
  .post-details-content .post-details-body .content .blockquote-area {
    padding: 0;
  }
}

.post-details-content .post-details-body .content .blockquote-area .blockquote-style {
  margin-bottom: 0;
}
.post-details-content .post-details-body .content .blockquote-area .blockquote-style p {
  color: #000;
  display: flex;
  font-size: 24px;
  letter-spacing: 0.25px;
  line-height: 36px;
}
@media only screen and (max-width: 1199px) {
  .post-details-content .post-details-body .content .blockquote-area .blockquote-style p br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .post-details-content .post-details-body .content .blockquote-area .blockquote-style p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px;
  }
}
.post-details-content .post-details-body .content .blockquote-area .blockquote-style p span {
  font-size: 60px;
  margin-right: 7px;
  margin-top: 1px;
}
.post-details-content .post-details-body .content .widget-tags.tags-style2 {
  border-bottom: 1px solid #e9ebed;
  margin-bottom: 60px;
  padding-bottom: 27px;
  padding-top: 31px;
}
@media only screen and (max-width: 1199px) {
  .post-details-content .post-details-body .content .widget-tags.tags-style2 {
    padding-top: 0;
  }
}
.post-details-content .post-details-body .content .widget-tags.tags-style2 ul li a {
  padding: 6.5px 12.41px 7.5px;
}
.post-details-content .post-details-body .related-post {
  padding: 25px 0 101px;
}
@media only screen and (max-width: 1199px) {
  .post-details-content .post-details-body .related-post {
    padding: 0 0 65px;
  }
}
@media only screen and (max-width: 991px) {
  .post-details-content .post-details-body .related-post {
    padding: 0 0 50px;
  }
}
.post-details-content .post-details-body .related-post .title {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1.04px;
  line-height: 1.1;
  margin-bottom: 46px;
}
.post-details-content .post-details-body .related-post .title span {
  color: var(--primary);
  font-weight: 300;
}
.post-details-content .post-details-body .related-post .post-items-style3 .post-item .thumb {
  margin-bottom: 18px;
}
.post-details-content .post-details-body .related-post .post-items-style3 .post-item .content .category {
  margin-bottom: 11px;
}
@media only screen and (max-width: 767px) {
  .post-details-content .post-details-body .related-post .post-items-style3 .post-item .content .category {
    display: block;
  }
}
.post-details-content .post-details-body .related-post .post-items-style3 .post-item .content .title {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;
  margin: 0 0 30px 0;
}
.post-details-content .post-details-body .related-post .post-items-style3 .post-item .content .title a {
  color: #000;
}
.post-details-content .post-details-body .related-post .post-items-style3 .post-item .content .title a:hover {
  color: var(--primary);
}

.comment-area {
  background-color: #eef1f3;
  padding: 125px 0 146px;
}
@media only screen and (max-width: 1199px) {
  .comment-area {
    padding: 95px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .comment-area {
    padding: 85px 0 90px;
  }
}
.comment-area .section-title {
  margin-bottom: 46px;
}
.comment-area .section-title .title {
  font-size: 36px;
  margin-bottom: 9px;
}
.comment-area .section-title p {
  letter-spacing: -0.2176px;
  margin-bottom: 0;
}

.widget-items  {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 47px;
  text-transform: uppercase;
}

.widget-tags ul li {
  color: #999999;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 0 6px 10px 0;
}
.widget-tags ul li a {
  background-color: #f3f3f3;
  border-radius: 0;
  color: #999999;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.148px;
  line-height: 1;
  padding: 7.5px 14px;
}
.widget-tags ul li a:hover {
  background-color: var(--primary);
  color: #fff;
}
.widget-tags ul li:nth-child(4) a {
  padding: 7.5px 10px;
}
.widget-tags.tags-style2 {
  padding-top: 1px;
}
.widget-tags.tags-style2 ul li a {
  background-color: transparent;
  border: 1px solid #d7d7d7;
  letter-spacing: -0.15px;
  padding: 6.5px 12.41px;
}
.widget-tags.tags-style2 ul li a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.widget-categories ul li {
  border-bottom: 1px solid #d3d8dc;
  color: #000;
  font-size: 14px;
  padding: 17px 0;
  letter-spacing: -0.093px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
}
.widget-categories ul li a {
  color: #000;
  display: block;
  font-size: 14px;
}
.widget-categories ul li a span {
  float: right;
}
.widget-categories ul li a:hover {
  color: var(--primary);
}
.widget-categories ul li:first-child {
  padding-top: 1px;
}
.widget-categories ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget-blog-post ul li {
  border-bottom: 1px solid #d3d8dc;
  display: flex;
  margin-bottom: 19px;
  padding-bottom: 19px;
  position: relative;
}
.widget-blog-post ul li .thumb {
  margin-right: 20px;
  min-width: 79px;
}
.widget-blog-post ul li .thumb img {
  border-radius: 0;
  width: auto;
}
.widget-blog-post ul li .content {
  position: relative;
  top: -5px;
}
.widget-blog-post ul li .content a {
  color: #000;
  display: block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.widget-blog-post ul li .content a:hover {
  color: var(--primary);
}
.widget-blog-post ul li .content span {
  color: #aba5a5;
  display: block;
  font-size: 14px;
}
.widget-blog-post ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget-search-box form input {
  background-color: #f5f5f5;
  border: none;
  box-shadow: none;
  height: 51px;
  padding: 10px 30px;
  width: 100%;
}
.widget-search-box form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.15px;
}
.widget-search-box form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.15px;
}
.widget-search-box form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.15px;
}
.widget-search-box form input:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.15px;
}

.sidebar-wrapper.blog-sidebar-wrapper {
  padding-left: 70px;
  padding-top: 3px;
}
@media only screen and (max-width: 1199px) {
  .sidebar-wrapper.blog-sidebar-wrapper {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar-wrapper.blog-sidebar-wrapper {
    padding-left: 0;
  }
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-title {
  letter-spacing: 0.323px;
  margin-bottom: 37px;
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-search-box {
  margin-bottom: 71px;
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-categories {
  margin-bottom: 71px;
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-categories ul {
  margin-bottom: 0;
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-blog-post {
  margin-bottom: 77px;
  padding-top: 7px;
}
.sidebar-wrapper.blog-sidebar-wrapper .widget-item .widget-tags.tags-style2 ul {
  margin-bottom: 0;
}


/*
-----------------------------------------------------------------------
  Footer CSS
-----------------------------------------------------------------------
*/
.footer {
  color: #666666;
  /* padding: 113px 0 0; */
  padding: 40px 0 0;
}
@media only screen and (max-width: 1199px) {
  .footer {
    padding: 68px 0 0;
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    padding: 98px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding: 0px 0 0;
  }
}

.footer.border-top-style:before {
  border-top: 1px solid #e5e5e5;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.footer .widget-title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.522px;
  margin: 29px 0 54px;
  text-transform: uppercase;
}
@media only screen and (max-width: 991px) {
  .footer .widget-title {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer .widget-title {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .footer .widget-title {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .footer .about-widget {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .about-widget {
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 575px) {
  .footer .about-widget {
    margin-bottom: 45px;
  }
}
.footer .about-widget .footer-logo {
  display: inline-block;
  margin-bottom: 29px;
}
.footer .about-widget p {
  margin-bottom: 40px;
  color: var(--white);
}
.footer .widget-posts {
  margin-top: -6px;
}
.footer .widget-posts .posts-item li {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 9px;
  padding-bottom: 11px;
}
.footer .widget-posts .posts-item li a {
  align-items: flex-end;
  color: var(--white);
  display: flex;
  letter-spacing: 0;
}
@media only screen and (max-width: 767px) {
  .footer .widget-posts .posts-item li a {
    align-items: flex-start;
  }
}
.footer .widget-posts .posts-item li a .icon {
  font-size: 15px;
  line-height: 20px;
  margin-right: 8px;
}
@media only screen and (max-width: 767px) {
  .footer .widget-posts .posts-item li a .icon {
    position: relative;
    top: 3px;
  }
}
.footer .widget-posts .posts-item li a:hover {
  color: #000;
}
.footer .widget-posts .posts-item li:last-child {
  border-bottom: none;
}
.footer .widget-contact-info {
  padding-top: 25px;
}
.footer .widget-contact-info li {
  border-top: 1px solid #e1e1e1;
  display: flex;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px;
  padding-top: 4px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.footer .widget-contact-info li i {
  color: var(--white);
  font-size: 20px;
  line-height: 26px;
  position: relative;
  top: -2px;
  width: 37px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.footer .widget-contact-info li a {
  color: var(--white);
}
.footer .widget-contact-info li:first-child {
  margin-bottom: 13px;
  padding-top: 5px;
  color: var(--white);
}
.footer .widget-contact-info li:first-child i {
  font-size: 22px;
  left: -2px;
  top: 1px;
  width: 55px;
}
@media only screen and (max-width: 575px) {
  .footer .widget-contact-info li:first-child i {
    width: 37px;
  }
}
@media (max-width: 359px) {
  .footer .widget-contact-info li:first-child i {
    width: 48px;
  }
}
.footer .widget-contact-info li:last-child {
  margin-bottom: 0;
  padding-top: 5px;
}
.footer .widget-contact-info li:last-child i {
  top: 0;
}
.footer .widget-contact-info li:hover {
  color: var(--primary);
}
.footer .widget-contact-info li:hover i {
  color: var(--primary);
}
.footer .widget-contact-info li:hover a {
  color: var(--primary);
}
.footer .widget-newsletter {
  border-bottom: 1px solid #e1e1e1;
  margin-top: -7px;
  padding-bottom: 41px;
}

@media only screen and (max-width: 575px) {
 
  .footer .widget-newsletter {
    border-bottom: none;
    padding-bottom: 10px;
  }
  .footers {
    display: table-column;
  }

}

.footer .widget-newsletter p {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 24px;
  color: var(--white);
}
.footer .widget-newsletter .newsletter-form {
  position: relative;
}
.footer .widget-newsletter .newsletter-form.input-btn-group {
  background-color: #f8f8f8;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control {
  background-color: #f8f8f8;
  border: none;
  border-radius: 0;
  height: 46px;
  margin-bottom: 0;
  padding: 6px 18px;
  position: relative;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  font-size: 14px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  font-size: 14px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  font-size: 14px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  font-size: 14px;
}
@media only screen and (max-width: 1399px) {
  .footer .widget-newsletter .newsletter-form.input-btn-group .form-control {
    width: calc(100% - 49px);
  }
}
.footer .widget-newsletter .newsletter-form.input-btn-group .form-control:focus {
  box-shadow: none;
  padding: 5px 17px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .btn-theme {
  background-color: var(--primary);
  border-radius: 0;
  color: #fff;
  height: 46px;
  min-height: auto;
  padding: 10px 12.5px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .btn-theme i {
  font-size: 22px;
}
.footer .widget-newsletter .newsletter-form.input-btn-group .btn-theme:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.footer .widget-social-icons {
  margin-bottom: 0;
  margin-left: -1px;
  margin-top: 14px;
}
.footer .widget-social-icons a {
  color: #999999;
  display: inline-block;
  font-size: 18px;
  margin-right: 13.84px;
}
.footer .widget-social-icons a:last-child {
  margin-right: 0;
}
.footer .widget-social-icons a:hover {
  color: var(--primary);
}
.footer .footer-bottom {
  margin-top: 84px;
  padding: 51px 0 48px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .footer .footer-bottom {
    margin-top: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .footer-bottom {
    margin-top: 30px;
    padding: 21px 0 18px;
  }
}
@media only screen and (max-width: 575px) {
  .footer .footer-bottom {
    margin-top: 70px;
  }
}
.footer .footer-bottom:before {
  border-top: 1px solid #e5e5e5;
  content: "";
  left: 50%;
  max-width: 1170px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
}
.footer .footer-bottom .widget-copyright p {
  color: var(--white);
  letter-spacing: 0;
  padding-top: 0;
}
.footer .footer-bottom .widget-copyright p span {
  color: var(--primary);
  font-weight: 700;
}
.footer .footer-bottom .widget-copyright p i {
  color: #f53400;
  font-size: 16px;
  margin: 0 5px;
}
.footer .footer-bottom .widget-copyright p a {
  color: var(--white);
  font-weight: 500;
}

.reveal-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: var(--grey3);
}

@media only screen and (max-width: 991px) {
  .reveal-footer {
    position: relative;
  }
}

.site-wrapper-reveal {
  position: relative;
}
@media only screen and (max-width: 991px) {
  .site-wrapper-reveal {
    margin-bottom: 0 !important;
  }
}
