.contenaireHocencter {
	padding-top: 90px;
}

.contenaireHorizontal {
	/* width: 80%; */
	height: 100px;
	/* margin: 0 auto; */
	/* font-size: 15px; */
}

/* ............................... */

.schedule .content .calendrier {

	font-family: 'Lato', sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: rgb(101, 99, 99);
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 22px;
    font-weight: 600;
    padding: 0px 0px 9px;
	text-align: center;
	position: relative;
	-webkit-text-stroke: 0.1vw #383d52;
	padding: 0px 1px 60px;
}

/* ............................... */

.titre h2 {
	margin-bottom: .5rem;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: rgb(101, 99, 99);
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 30px;
    font-weight: 600;
	padding: 60px 7px 24px;
	text-align: center;
}

.schedule {
    /* margin: 50px;
    padding: 0px 30px; */

	padding-top: 41px;
}

.schedule p {
	font-size: 18px;
    line-height: 37px;
    color: #6c6c6c;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
	margin: 30px;
}

.schedule .content .box{
	flex:0 0 50%;
	max-width: 100%;
	margin-top: 30px;
}

.schedule .content .text h2{
    font-size: 30px;
    color: #000000;
    padding: 0px 8px 20px;
}

.schedule .content .text p {
	font-size: 18px;
    line-height: 30px;
    color:var(--grey);
    margin: 0;
    font-family: 'Lato', sans-serif;
}

.schedule .content .timing .table{
	width: 100%;
	border-collapse: collapse;
}

.schedule .content .timing .table td {
	border:1px solid #eae8e8;
	/* padding: 8px; */
	font-size: 18px;
	text-align: justify;
	color:var(--grey);
	line-height: 24px;	
	margin: 0;
	font-family: 'Lato', sans-serif;
}

.schedule .content .timing .table tr:nth-child(even) td.day{
	background-color: #222222;
}
.schedule .content .timing .table tr:nth-child(odd) td.day {
	background-color: #444444;
}
.schedule .content .timing .table tr:nth-child(1)   td.day{
	background-color: var(--primary);
}
.schedule .content .timing .table tr td.day{
	color:var(--white);
	border-color:transparent;
}

@media(max-width: 767px) {

	/* .schedule {
		margin: 0px;
		padding: 0px 2px;
	} */

	.schedule {
		padding-top: 1px;
		margin: -14px;
	}

	.schedule .content .text p {
		font-size: 18px;
		line-height: 30px;
		color:var(--grey);
		margin: 0;
		font-family: 'Lato', sans-serif;
	}

	.schedule .content .timing .table td {
		border:1px solid #eae8e8;
		padding: 8px;
		font-size: 18px;
		text-align: justify;
		color:var(--grey);
		line-height: 24px;	
		margin: 0;
		font-family: 'Lato', sans-serif;
	}

    .schedule .content {
        flex-wrap: wrap;
		padding: 8px 11px 33px;
	}
	
    .schedule .content .box{
        flex: 0 0 100%;
        max-width: 100%;	
	}
	
	.schedule p {
		font-size: 18px;
		line-height: 37px;
		color: #6c6c6c;
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		text-align: left;
		margin: 14px;
	}
	
}
  
@media(max-width: 550px){

	/* .schedule {
		margin: 0px;
		padding: 0px 2px;
	} */

	.schedule {
		padding-top: 1px;
		margin: -14px;
	}

	.schedule .content {
        flex-wrap: wrap;
		padding: 8px 0px 33px;
	}

	.schedule .content .text p {
		font-size: 18px;
		line-height: 30px;
		color:var(--grey);
		margin: 9px;
		font-family: 'Lato', sans-serif;
	}

	.schedule .content .timing .table td{
		border:1px solid #eae8e8;
		padding: 8px;
		font-size: 15px;
		text-align: justify;
		color:var(--grey);
		line-height: 24px;	
		margin: 0;
		font-family: 'Lato', sans-serif;
	}

	.schedule p {
		font-size: 18px;
		line-height: 37px;
		color: #6c6c6c;
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		text-align: justify;
		margin: 14px;
	}

	.contenaireHocencter {
		padding-top: 50px;
	}

    .calendrier {
		font-size: 12px;
		text-align: center;
		line-height: 35px;
		color: #656363;
		padding: 0px 1px 60px;
	}


}