/*
-----------------------------------------------------------------------
  Department CSS
-----------------------------------------------------------------------
*/

.department-area {
  padding: 4px 0 95px;
}

@media only screen and (max-width: 1199px) {
  .department-area {
    padding: 42px 0 100px;
  }
}
@media only screen and (max-width: 991px) {
  .department-area {
    padding: 15px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .department-area {
    padding: 15px 0 90px;
  }
}
@media only screen and (max-width: 575px) {
  .department-area {
    padding: 15px 0 0px;
  }
}
@media only screen and (max-width: 1199px) {
  .department-area .container {
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.department-wrpp {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1199px) {
  .department-wrpp {
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) {
  .department-wrpp {
    justify-content: normal;
    flex-direction: column-reverse;
  }
}

.department-wrpp .department-content {
  max-width: 800px;
  width: 100%;
  border-bottom: 1px solid #f7f1f1;
}

@media only screen and (max-width: 1199px) {
  .department-wrpp .department-content {
    max-width: 700px;
  }
}
@media (max-width: 1023px) {
  .department-wrpp .department-content {
    max-width: 669px;
  }
}
@media only screen and (max-width: 991px) {
  .department-wrpp .department-content {
    /* margin-bottom: 80px;.page-title-area .page-title-content */
    max-width: none;
  }
}
.department-wrpp .sidebar-wrapper {
  margin-top: 20px;
  max-width: 300px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .department-wrpp .sidebar-wrapper {
    margin-right: 30px;
    max-width: 265px;
  }
}
@media only screen and (max-width: 991px) {
  .department-wrpp .sidebar-wrapper {
    max-width: none;
  }
}

.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav {
  /* margin-bottom: 50px; */
  /* padding-top: 34px; */
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul {
  margin-bottom: 0;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li {
  margin-bottom: 10px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a {
  background-color: #f8f8f8;
  border-radius: 3px;
  color: #747474;
  display: block;
  font-size: 16px;
  letter-spacing: 0;
  padding: 11.5px 33px 11.5px 14px;
  position: relative;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a:hover, .department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a.active {
  background-color: var(--primary);
  color: var(--white);
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a:hover:before, .department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a.active:before {
  color: var(--white);
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li a:before {
  color: #747474;
  content: "";
  font-family: "IcoFont";
  font-size: 16px;
  position: absolute;
  right: 30px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-side-nav ul li:last-child {
  margin-bottom: 0;
}

.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours {
  background-color: var(--primary);
  border-radius: 1px;
  letter-spacing: 0;
  margin-bottom: 50px;
  padding: 100px 30px 100px;
  margin-top: 42px;
}

.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .title {
  color: var(--white);
  font-size: 18px;
  margin-bottom: 31px;
  text-transform: uppercase;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours ul {
  margin-bottom: 41px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours ul li {
  color: var(--white);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours ul li:before {
  border-bottom: 1px dashed var(--white);
  bottom: 7px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours ul li:last-child {
  margin-bottom: 0;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .work-day {
  background-color: var(--primary);
  position: relative;
  z-index: 1;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .work-hours {
  background-color: var(--primary);
  position: relative;
  z-index: 1;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .btn-theme.btn-white {
  background-color: var(--white);
  border-radius: 3px;
  color: var(--black);
  display: block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  min-height: auto;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .btn-theme.btn-white {
    font-size: 13px;
    padding: 8px 6px;
  }
}
.department-wrpp .sidebar-wrapper .widget-item .widget-work-hours .btn-theme.btn-white:hover {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form {
  background-color: #f2f2f2;
  padding: 48px 30px 50px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form .title {
  color: var(--black);
  font-size: 18px;
  margin-bottom: 37px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form .title {
    font-size: 16px;
  }
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group {
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control {
  background-color: var(--white);
  border: none;
  border-radius: 3px;
  color: var(--black);
  font-size: 14px;
  height: 46px;
  padding: 12px 20px;
  width: 100%;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #888;
  font-size: 13px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #888;
  font-size: 13px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #888;
  font-size: 13px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #888;
  font-size: 13px;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group textarea {
  background-color: var(--white);
  border: none;
  border-radius: 3px;
  font-size: 14px;
  min-height: 150px;
  padding: 12px 20px;
  width: 100%;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group .btn-theme {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 32px;
  margin-top: 22px;
  padding: 8px 30px;
  text-transform: uppercase;
  width: 100%;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group.datepicker-group {
  position: relative;
}
.department-wrpp .sidebar-wrapper .widget-item .widget-appointment-form form .form-group.datepicker-group label {
  bottom: 8px;
  color: #888888;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 20px;
}

.department-content .title {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -1.377px;
  line-height: 1.1;
  margin-bottom: 34px;
  display: inline-block;
}

@media only screen and (max-width: 1199px) {
  .department-content .title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .department-content .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .department-content .title {
    font-size: 24px;
  }
}
.department-content .title span {
  font-weight: 300;
  color: var(--primary);
}
.department-content .department-gallery {
  margin-bottom: 38px;
}
@media only screen and (max-width: 767px) {
  .department-content .department-gallery {
    margin-bottom: 30px;
  }
}
.department-content .department-gallery .gallery-slider .swiper-slide img {
  border-radius: 2px;
  width: 100%;
}

.department-content .department-gallery .swiper-button-next,
.department-content .department-gallery .swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: var(--white);
  font-size: 18px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.department-content .department-gallery .swiper-button-next:after,
.department-content .department-gallery .swiper-button-prev:after {
  display: none;
}
.department-content .department-gallery .swiper-button-next:hover,
.department-content .department-gallery .swiper-button-prev:hover {
  background-color: var(--primary);
}
.department-content .department-gallery .swiper-button-prev {
  left: 30px;
}
@media only screen and (max-width: 575px) {
  .department-content .department-gallery .swiper-button-prev {
    left: 15px;
  }
}
.department-content .department-gallery .swiper-button-next {
  right: 30px;
}
@media only screen and (max-width: 575px) {
  .department-content .department-gallery .swiper-button-next {
    right: 15px;
  }
}

.department-content .content {
  margin-bottom: 43px;
  /* padding: 0 18px 0 2px; */
}

.department-content .content p {
  position: relative;
  line-height: 38px;
  font-weight: lighter;
  text-align: justify;
  color: var(--black);
  font-weight: 400;
}

.department-content .content ul {
  margin-bottom: 0;
}
.department-content .content ul li {
  color: #888;
  line-height: 36px;
}
.department-content .content ul.list-style1 {
  padding-top: 1px;
}

.department-content .content ul.list-style1 li {
  padding-left: 32px;
  position: relative;
  line-height: 38px;
  font-weight: lighter;
  text-align: justify;
  color: var(--black);
  font-weight: 400;
}

.department-content .content ul.list-style1 li:before {
  color: var(--primary);
  content: "";
  font-family: "IcoFont";
  left: 0;
  position: absolute;
  top: 2px;
}

.department-content .price-list-col2 {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  margin-bottom: 79px;
  padding-bottom: 67px;
}
@media only screen and (max-width: 991px) {
  .department-content .price-list-col2 {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .department-content .price-list-col2 {
    margin-bottom: 49px;
    padding-bottom: 37px;
  }
}

@media only screen and (max-width: 575px) {
  .department-content .price-list-col2 {
    display: block;
  }
}
.department-content .price-list-col2 .price-list {
  max-width: 373px;
  width: 100%;
}
.department-content .price-list-col2 .price-list:last-child {
  max-width: 362px;
}
@media only screen and (max-width: 1199px) {
  .department-content .price-list-col2 .price-list:last-child {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .department-content .price-list-col2 .price-list:last-child {
    margin-left: 0;
    margin-top: 50px;
  }
}
.department-content .price-list h4 {
  color: var(--black);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.25px;
  margin-bottom: 40px;
}
.department-content .price-list ul li {
  color: var(--grey2);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 26px;
  position: relative;
}
.department-content .price-list ul li:before {
  border-bottom: 1px dashed var(--grey2);
  bottom: 2px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.department-content .price-list ul li:last-child {
  margin-bottom: 0;
}
.department-content .price-list ul li .service-name {
  background-color: var(--white);
  position: relative;
  z-index: 1;
}
.department-content .price-list ul li .service-price {
  background-color: var(--white);
  color: #568701;
  position: relative;
  z-index: 1;
}
.department-content .team-single {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 69px;
  padding-bottom: 83px;
}
@media (max-width: 702px) {
  .department-content .team-single {
    margin-bottom: 49px;
    padding-bottom: 63px;
  }
}
/*-------- Faq Style Css --------*/


.faq-area.title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1.377px;
  line-height: 1.1;
  margin-bottom: 34px;
  display: inline-block;
}

.faq-area .title span {
  font-weight: 300;
  color: var(--primary);
}


.faq-area .accordian-content .accordion .accordion-item {
  border: 1px solid #eaeaea;
  border-radius: 3px;
  margin-bottom: 15px;
  overflow: visible;
}

.faq-area .accordian-content .accordion .accordion-item .accordion-header {
  padding: 0;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button {
  background-color: var(--white);
  box-shadow: none;
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
  padding: 13px 30px 14px;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button:before {
  background-color: var(--primary);
  background-image: none;
  border-radius: 3px 3px 0 0;
  height: 4px;
  top: -1px;
  z-index: 1;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button .icon {
  color: var(--primary);
  font-size: 18px;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  color: var(--black);
  padding: 14px 30px;
  font-weight: 400;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:before {
  background-color: transparent;
  background-image: none;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed .icon {
  color: #3e4140;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:hover {
  background-color: transparent;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:hover:before {
  background-color: transparent;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-collapse {
  border: none;
}
.faq-area .accordian-content .accordion .accordion-item .accordion-body {
  letter-spacing: 0;
  line-height: 24px;
  padding: 4px 28px 23px 30px;
}

.department-content .faq-area {
  /* border-bottom: 1px solid #eaeaea; */
  /* padding-bottom: 60px; */
}


@media only screen and (max-width: 767px) {
  .department-content .faq-area {
    /* margin-bottom: 59px; */
  }
}

.department-content .faq-area .title {
  font-size: 36px;
  letter-spacing: -1.04px;
  margin-bottom: 65px;
}

.department-content .faq-area p {
  font-size: 22px;
  color: var(--grey-alt);
  margin: 0;
  line-height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  text-align: justify;
}


@media only screen and (max-width: 767px) {
  .department-content .faq-area .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .faq-area.title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.377px;
    line-height: 1.1;
    margin-bottom: 34px;
    display: inline-block;
  }
}

.department-content .case-area .section-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 63px;
}

@media only screen and (max-width: 767px) {
  .department-content .case-area .section-title {
    margin-bottom: 35px;
  }
}
.department-content .case-area .section-title .title {
  font-size: 36px;
  letter-spacing: -1.04px;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .department-content .case-area .section-title .title {
    font-size: 30px;
  }
}
.department-content .case-area .section-title .btn-link {
  color: var(--primary);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  top: -1px;
}
@media only screen and (max-width: 479.98px) {
  .department-content .case-area .section-title .btn-link {
    font-size: 11px;
  }
}
.department-content .case-area .section-title .btn-link:before {
  background-color: #94c3c3;
  bottom: -2px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.department-content .case-area .section-title .btn-link:after {
  background-color: var(--primary);
  bottom: -4px;
  content: "";
  height: 1px;
  left: 3px;
  position: absolute;
  width: calc(100% - 3px);
}
.department-content .case-area .case-col2 {
  display: flex;
  justify-content: space-between;
}
.department-content .case-area .case-col2 .case-item {
  max-width: 385px;
  width: 100%;
}


@media only screen and (max-width: 1199px){

  .department-content .content p {
    color: rgb(0, 0, 0);
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 400;
  }
  
  .department-content .content ul.list-style1 li {
    padding-left: 32px;
    position: relative;
    font-size: 22px;
    line-height: 38px;
    font-weight: 400;
  }
  
}

@media only screen and (max-width: 767px){
  .department-content .content p {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 400;
    color: var(--black);
  }

  .department-content .content ul.list-style1 li {
    padding-left: 32px;
    position: relative;
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 400;
    color: var(--black);
  }

} 

@media only screen and (max-width: 479.98px) {
  .department-content .content p {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "Lato",sans-serif;
    text-align: justify;
    color: var(--grey-alt);;
    line-height: 30px;
  }

  .department-content .content ul.list-style1 li {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "Lato",sans-serif;
    text-align: justify;
    color: var(--grey-alt);
    line-height: 30px;
  }
}