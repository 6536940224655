.section-titles {
    margin-bottom: 80px;
    /* text-align: left */
}

@media only screen and (max-width: 991px) {
    .section-titles {
        margin-bottom: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .section-titles {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 575px) {
    .section-titles {
        margin-bottom: 0px;
    }
}

.section-titles h5 {
    color: var(--grey1);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .section-titles h5 {
        font-size: 12px;
        margin-bottom: 12px;
        /* padding-top: 32px; */
        /* text-align:'left' */
    }
}

.section-titles .title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1.377px;
    line-height: 1.1;
    margin-bottom: 25px;
}

.section-titles .title:after {
    background-color: var(--primary);
    border-radius: 3px;
    bottom: -14px;
    content: "";
    height: 5px;
    left: 0%;
    position: absolute;
    /* transform: translateX(-202%); */
    width: 86px;
}

@media only screen and (max-width: 1199px) {
    .section-titles .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .section-titles .title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 575px) {
    .section-titles .title {
        font-size: 24px;
    }
}

.section-titles .title span {
    color: var(--primary);
    font-weight: 300;
}

.section-titles .desc {
    margin-bottom: 55px;
}

.section-titles .desc p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .section-titles .desc p br {
        display: none;
    }
}

.section-titles .separator-line {
    border-bottom: 1px solid var(--primary);
    display: block;
    margin: 0 auto 40px;
    width: 100px;
}

.section-titles .list-style li {
    color: var(--grey2);
    font-size: 14px;
    margin-bottom: 16px;
    padding-left: 29px;
    position: relative;
}

.section-titles .list-style li i {
    color: var(--primary);
    font-size: 14px;
    left: 0;
    position: absolute;
    top: 5px;
}

.section-titles .list-style li:last-child {
    margin-bottom: 0;
}

.section-titles.section-light h5 {
    color: var(--white);
}

.section-titles.section-light .title {
    color: var(--white);
}

.section-titles.section-light .desc p {
    color: var(--white);
}

.section-titles.section-light .separator-line {
    border-bottom-color: #fff !important;
}

.section-titles.section-light .separator-line.separator-double:before {
    border-bottom-color: #fff !important;
}

/*
-----------------------------------------------------------------------
	Form CSS
-----------------------------------------------------------------------
*/
.input-btn-group {
    align-items: center;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 35px 100px 0px rgba(171, 181, 189, 0.35);
    display: flex;
    padding: 5px 5px 5px 0;
}

.input-btn-group .form-control {
    border: none;
    border-radius: 0;
    border-right: 2px solid #eaeaea;
    height: 24px;
    padding-left: 30px;
}

.input-btn-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #aba5a5;
}

.input-btn-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #aba5a5;
}

.input-btn-group .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #aba5a5;
}

.input-btn-group .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #aba5a5;
}

.input-btn-group .form-control.no-border {
    border: none;
}

.input-btn-group .form-control:focus {
    box-shadow: none;
}

.input-btn-group .btn-theme {
    border-radius: 5px;
    height: 50px;
}

.appointments-form .form-group {
    margin-bottom: 16px;
}

.appointments-form .form-group .form-control {
    /* background-color: transparent;
    background-clip: inherit;
    border: none;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0;
    color: var(--black);
    font-size: 14px;
    height: auto;
    padding: 0 1px 10px; */

           /* background-color: transparent; */
            background-clip: inherit;
            /* border: none; */
            /* border-bottom: 1px solid #e1e1e1; */
            border-radius: 5;
            /* color: var(--black); */
            /* font-size: 14px; */
            height: auto;
            padding: 11px -16px 17px;
            border-color: #d6d6d6;
            border-width: 1px;
            height: 45px;
}

.appointments-form .form-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--grey1);
}

.appointments-form .form-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--grey1);
}

.appointments-form .form-group .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--grey1);
}

.appointments-form .form-group .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: var(--grey1);
}

.appointments-form .form-group .form-control:focus {
    border: 1px solid var(--primary);
    box-shadow: none;
    outline: none;
}

.appointments-form .form-group textarea {
    background-clip: inherit;
    border-radius: 5;
    height: auto;
    padding: 11px -16px 17px;
    border-color: #d6d6d6;
    border-width: 1px;
    width: 100%;
}

.appointments-form .form-group textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--grey1);
}

.appointments-form .form-group textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--grey1);
}

.appointments-form .form-group textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--grey1);
}

.appointments-form .form-group textarea:-moz-placeholder {
    /* Firefox 18- */
    color: var(--grey1);
}

.appointments-form .form-group textarea:focus {
    border-bottom: 1px solid var(--black);
    box-shadow: none;
    outline: none;
}

.appointments-form .form-group .btn-theme {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.428px;
    margin-top: 40px;
    min-height: 62px;
    padding: 0 47.43px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {

    .appointments-form .form-group {
            margin-bottom: 18px; 
            /* expace entre button */
    }

    .appointments-form .form-group .btn-theme {
        font-size: 16px;
        min-height: 50px;
        padding: 0 20px;
    }

}

.appointments-form .form-group .btn-theme:hover,
.appointments-form .form-group .btn-theme:focus {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
}

.appointments-form .form-group.datepicker-group {
    position: relative;
}

.appointments-form .form-group.datepicker-group label {
    bottom: -1px;
    color: var(--grey1);
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 0;
}

.appointments-form-style1 {
    background-color: var(--white);
    box-shadow: 0px 50px 120px 0px rgb(255, 255, 255);
    margin: 0 auto -47px;
    max-width: 1310px;
    text-align: center;
    transform: translateY(-90px);
}

@media only screen and (max-width: 812px) {
    .appointments-form-style1 {
        background-color: var(--white);
        /* box-shadow: 0px 50px 120px 0px rgba(255, 255, 255, 0.15); */
        margin: 0 auto -47px;
        max-width: 1310px;
        text-align: center;
        transform: translateY(-148px);
    }
}

@media only screen and (max-width: 1399px) {
    .appointments-form-style1 {
        max-width: 1170px;
    }
}

@media only screen and (max-width: 1199px) {
    .appointments-form-style1 {
        margin-bottom: 0;
        max-width: none;
        transform: none;
    }
}

.appointments-form-style1 .appointments-form {
    padding: 125px 0 100px;
}

@media only screen and (max-width: 1399px) {
    .appointments-form-style1 .appointments-form {
        padding: 100px 0;
    }
}

@media only screen and (max-width: 1199px) {
    .appointments-form-style1 .appointments-form {
        padding: 100px 0;
    }
}

@media only screen and (max-width: 767px) {
    .appointments-form-style1 .appointments-form {
        padding: 80px 0;
    }
}

@media only screen and (max-width: 575px) {
    .appointments-form-style1 .appointments-form {
        padding: 0px 0;
        /* /===================70====================/ */
    }
}

.contact-form .form-group {
    margin-bottom: 30px;
}

.contact-form .form-group .form-control {
    background-clip: inherit;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    font-size: 14px;
    height: 50px;
    padding: 12px 30px;
}

.contact-form .form-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--grey1);
}

.contact-form .form-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--grey1);
}

.contact-form .form-group .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--grey1);
}

.contact-form .form-group .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: var(--grey1);
}

.contact-form .form-group .form-control:focus {
    border: 1px solid var(--primary);
    box-shadow: none;
    outline: none;
}

.contact-form .form-group textarea {
    border: 1px solid #d9d9d9;
    border-radius: 0;
    font-size: 14px;
    min-height: 170px;
    padding: 20px 30px 10px;
    width: 100%;
}

.contact-form .form-group textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--grey1);
}

.contact-form .form-group textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--grey1);
}

.contact-form .form-group textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--grey1);
}

.contact-form .form-group textarea:-moz-placeholder {
    /* Firefox 18- */
    color: var(--grey1);
}

.contact-form .form-group textarea:focus {
    border: 1px solid var(--primary);
    box-shadow: none;
    outline: none;
}

.contact-form .form-group .btn-theme {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 51px;
    padding: 10px 26.05px;
}

.contact-form .form-group .btn-theme:hover,
.contact-form .form-group .btn-theme:focus {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
}

.comment-form-wrap form .comment-form-content .form-group {
    margin-bottom: 30px;
}

.comment-form-wrap form .comment-form-content .form-group .form-control {
    border: none;
    border-radius: 0;
    font-size: 14px;
    height: 49px;
    padding: 7px 21px;
}

.comment-form-wrap form .comment-form-content .form-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--grey1);
}

.comment-form-wrap form .comment-form-content .form-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--grey1);
}

.comment-form-wrap form .comment-form-content .form-group .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--grey1);
}

.comment-form-wrap form .comment-form-content .form-group .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: var(--grey1);
}

.comment-form-wrap form .comment-form-content .form-group .form-control:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.comment-form-wrap form .comment-form-content .form-group textarea.form-control {
    min-height: 217px;
}

.comment-form-wrap form .comment-form-content .form-group .btn-theme {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-top: 17px;
    min-height: 60px;
    padding: 10px 28.96px;
}

.search-box-wrapper {
    background-color: rgba(51, 153, 153, 0.9);
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.search-box-wrapper .search-box-content-inner {
    width: 70%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
    transition: transform 0.4s;
    opacity: 0;
    visibility: hidden;
}

@media only screen and (max-width: 575px) {
    .search-box-wrapper .search-box-content-inner {
        width: 100%;
        padding: 0 20px;
    }
}

.search-box-wrapper.show .search-box-content-inner {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.search-box-wrapper .search-close {
    color: var(--white);
    font-size: 70px;
    line-height: 1;
    position: absolute;
    top: 50px;
    right: 100px;
}

@media only screen and (max-width: 767px) {
    .search-box-wrapper .search-close {
        top: 20px;
        right: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .search-box-wrapper .search-close {
        top: 30px;
        right: 30px;
    }
}

.search-box-wrapper .search-close:hover {
    color: var(--primary);
    transform: rotate(90deg);
}

.search-box-form-wrap {
    width: 100%;
}

.search-box-form-wrap input[type=search] {
    background-color: transparent;
    border: none;
    border-bottom: 5px solid var(--primary);
    color: var(--primary);
    display: block;
    font-size: 120px;
    line-height: 1;
    padding: 20px 0;
    width: 100%;
}

@media only screen and (max-width: 991px) {
    .search-box-form-wrap input[type=search] {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .search-box-form-wrap input[type=search] {
        font-size: 40px;
    }
}

.search-box-form-wrap .search-note {
    color: var(--primary);
    font-weight: 700;
    text-align: right;
    margin-top: 20px;
}

.subscribe-form .subscribe-form-email {
    border: 1px solid #ddd;
    border-radius: 30px;
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative;
}

.subscribe-form .subscribe-form-email:before {
    content: "";
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    line-height: 60px;
}

.subscribe-form .subscribe-form-email input {
    background-color: transparent;
    border: none;
    border-radius: 0 30px 30px 0;
    color: var(--primary);
    height: 60px;
    line-height: 60px;
    padding-left: 35px;
    width: 100%;
}

.subscribe-form .subscribe-form-email input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--primary);
}

.subscribe-form .subscribe-form-email input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--primary);
}

.subscribe-form .subscribe-form-email input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--primary);
}

.subscribe-form .subscribe-form-email input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--primary);
}

.subscribe-form .subscribe-form-submit input {
    background-color: var(--primary);
    border: none;
    border-radius: 30px;
    color: var(--white);
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    margin-bottom: 30px;
    text-transform: uppercase;
    width: 100%;
}

.subscribe-form p {
    color: var(--primary);
    font-size: 14px;
    line-height: 30px;
}

.subscribe-form.inline-style {
    display: flex;
    justify-content: center;
}

.subscribe-form.inline-style .subscribe-form-email {
    border-color: var(--black);
    border-right: 0;
    border-radius: 0;
    height: 60px;
    margin-bottom: 0;
    padding-left: 50px;
    width: 478px;
}

/* // form */

.heading {
    font-size: 20px;
    color: #424040;
    font-weight: 600;
}

.input-text-box {
    border-radius:0;
    font-size: 15px !important;
    height: 45px !important;
    letter-spacing: 1px !important;
}

.form-control:focus {
    box-shadow: none !important;
    border-color:#222
}

.btn-sign-in {
    background: #c43421 !important;
    border-radius:5px;
    color: #fff !important;
    padding: 10px 50px !important;
    border-radius: 5px !important;
}

.btn-sign-up {
    background: #4287f5 !important;
    border-radius:5px;
    color: #fff !important;
    padding: 10px 50px !important;
    border-radius: 5px !important;
}

.blog-form input:focus {
    border-color: #222
}

.description-box {
    border-radius:5px;
    height: 150px;
    resize: none;
    padding-top: 20px !important;
    letter-spacing: 1px !important;
}

.btn-add {
    background: var(--primary) ;
    border-radius:5px;
    color: #fff !important;
    padding: 10px 50px !important;
}


.react-tag-input {
    height: 45px !important;
}

.react-tag-input:focus {
    border-color: #4287f5
}

.catg-dropdown {
    width: 100%;
    border-radius: 4px;
    height: 45px;
    color: #806a78;
}

.catg-dropdown:focus {
    outline: none !important;
    border-color: #bf7034
}

.trending {
    float: left;
    color: #806a78;
    font-size: 18px;
    margin: auto;
}

