.contact {
    padding:35px 35px;
    background-color: #4d4d4d;
    margin: 0px;
}

.contact .content{
    -webkit-display: flex;
    display: flex;
    margin: 30px;
}

.contact .content .box{
	flex:0 0 50%;
	max-width: 50%;
	padding: 15px;
}

.contact .content .form input,
.contact .content .form textarea {
	height: 45px;
	width: 100%;
	padding:6px 12px;
	margin-bottom: 25px;
	background-color: transparent;
	border:1px solid var(--white);
	font-family: 'Lato', sans-serif;
	color:#fff;
	font-size: 15px;
}

.contact .content .form input:focus,
.contact .content .form textarea:focus{
 outline: none;
}

.contact .content .form input::placeholder,
.contact .content .form textarea::placeholder{
	color:#fff;
}

.contact .content .form textarea{
	height: 115px;
}

.contact .content .form button{
	border:none;
	outline: none;
	box-shadow: none;
	height: 45px;
	padding:0px 50px;
	border:1px solid transparent;
	background-color: var(--primary);
	color:var(--white);
	font-size: 15px;
	transition: all .5s ease;
	cursor: pointer;
	border-radius: 3px;
}

.contact .content .form button:hover{
	background-color:transparent;
	color:var(--white); 
    border-color:var(--white);

	/* padding: 0px 0px 20px;
    margin-top: 7px; */
}
/* ------------------------------------------------------- */

.contact .text span {
	font-weight: 300;
	color: var(--primary);
}

@media only screen and (max-width: 767px) {
	.contact .content {
	  margin-bottom: 59px;
	}
  }
  .contact .content .text {
	font-size: 36px;
	letter-spacing: -1.04px;
	margin-bottom: 65px;
	color: var(--white);
  }

  @media only screen and (max-width: 767px) {
	.contact .content .text {
	  font-size: 30px;
	  margin-bottom: 30px;
	}
}

.contact .content .text p {
	font-size: 18px;
	line-height: 31px;
	color:var(--grey-alt);
	margin: 0;
	font-family: 'Lato', sans-serif;
}

.contact .content .text .info ul{
	list-style: none;
	padding:0;
	margin:0;
	margin-top: 20px;
}

.contact .content .text .info li{
	display: block;
    margin-bottom: 15px;
    color: var(--grey-alt);
    font-size: 18px;
    letter-spacing: 1px;
    position: relative;
    padding-left: 40px;
    /* line-height: 30px; */
    font-family: 'Lato', sans-serif;
}

.contact .content .text .info li span{
	display: inline-block;
    position: absolute;
    left: 3px;
    top: 4px;
    font-size: 24px;
    color: var(--primary);
}
.contact .content .text .social {
	padding-top: 30px;
}
.contact .content .text .social div {
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	background-color: var(--primary);
    text-decoration: none;	
    display: inline-block;
    margin-right: 10px;
    transition: all .5s ease;
	border:1px solid transparent;
	border-radius: 2px;
} 
.contact .content .text .social a:hover{
	background-color: transparent;
	border-color: var(--white);
}
.contact .content .text .social div span{
	color:var(--white);
	font-size: 20px;

}
.contact .content .text .copy {
	border-top:1px solid #a8a8a8;
	margin-top: 30px;
	padding-top: 20px;
	color:var(--grey-alt);
	font-size: 16px;
	font-family: 'Lato', sans-serif;
    line-height: 30px;
}

@media(max-width: 767px){

    .contact .content{
        flex-wrap: wrap;
    }
    .contact .content .box {
        flex: 0 0 100%;
        max-width: 100%;
    }

	.contact {
		padding:18px 0px;
		background-color: #000;
		margin: 1px;
	}

	.contact .content{
		-webkit-display: flex;
		display: flex;
		margin: 0px;
	}

}

@media(max-width: 550px) {

    .contact .content{
        flex-wrap: wrap;
    }
    .contact .content .box {
        flex: 0 0 100%;
        max-width: 100%;
    }

	.contact {
		padding:18px 0px;
		background-color: #4d4d4d;
		margin: 1px;
	}

	.contact .content{
		-webkit-display: flex;
		display: flex;
		margin: 0px;
	}

}
.reveal-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
}
@media only screen and (max-width: 991px) {
	.reveal-footer {
		position: relative;
	}
}

