/*
-----------------------------------------------------------------------
	Team CSS
-----------------------------------------------------------------------
*/
.team-area.team-default-area {
    padding: 137px 0 122px;
  }
  @media only screen and (max-width: 1199px) {
    .team-area.team-default-area {
      padding: 98px 0 92px;
    }
  }
  @media only screen and (max-width: 767px) {
    .team-area.team-default-area {
      padding: 78px 0 72px;
    }
  }
  @media only screen and (max-width: 575px) {
    .team-area.team-default-area {
      padding: 68px 0 62px;
    }
  }
  .team-area.team-default-area .section-title {
    margin-bottom: 64px;
  }
  @media only screen and (max-width: 767px) {
    .team-area.team-default-area .section-title {
      margin-bottom: 60px;
    }
  }
  @media only screen and (max-width: 575px) {
    .team-area.team-default-area .section-title {
      margin-bottom: 50px;
    }
  }
  
  .team-member {
    border: 1px solid #e7e7e7;
    padding: 24px;
    position: relative;
  }
  @media only screen and (max-width: 767px) {
    .team-member {
      padding: 7px;
    }
  }
  .team-member .thumb {
    overflow: hidden;
  }
  .team-member .thumb img {
    width: 100%;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
  }
  .team-member .content {
    padding: 35px 27px 24px;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    .team-member .content {
      padding: 30px 10px 24px;
    }
  }
  .team-member .content .member-info .name {
    color: var(--black);
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.25px;
    margin-bottom: 27px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 767px) {
    .team-member .content .member-info .name {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 575px) {
    .team-member .content .member-info .name {
      font-size: 16px;
    }
  }
  .team-member .content .member-info .name a {
    color: var(--black);
  }
  .team-member .content .member-info p {
    color: var(--grey1);
    margin-bottom: 36px;
  }
  @media only screen and (max-width: 767px) {
    .team-member .content .member-info p {
      margin-bottom: 20px;
    }
  }
  .team-member .content .member-info .btn-link {
    color: var(--grey2);
    font-size: 14px;
    font-weight: 400;
  }
  @media only screen and (max-width: 767px) {
    .team-member .content .member-info .btn-link {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 575px) {
    .team-member .content .member-info .btn-link {
      font-size: 12px;
    }
  }
  .team-member .content .member-info .btn-link:hover {
    color: var(--primary);
    font-weight: 700;
  }
  .team-member .content .member-info .designation {
    color: var(--black);
    font-size: 12px;
    margin-bottom: 5px;
  }
  .team-member .content .member-info .social-icons a {
    color: var(--black);
    display: inline-block;
    font-size: 16px;
    padding: 0 10px;
  }
  .team-member:hover .thumb img {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  .team-member:hover .content .member-info .name {
    color: var(--primary);
  }
  
  .team-slider-container .swiper-pagination {
    align-items: center;
    display: flex;
    margin: 70px auto 0;
    min-height: 18px;
    max-width: 71px;
    position: relative;
  }
  .team-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #ccc;
    border-radius: 50%;
    height: 12px;
    margin: 0 5px;
    opacity: 1;
    width: 12px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .team-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:hover {
    background-color: var(--primary);
  }
  .team-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--primary);
    height: 17px;
    width: 17px;
  }