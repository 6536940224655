  /*
  -----------------------------------------------------------------------
    About CSS
  -----------------------------------------------------------------------
  */
  .about-area {
      padding: 145px 0 154px;
  }
  @media only screen and (max-width: 1199px) {
    .about-area {
      padding: 90px 0 65px;
    }
  }
  @media only screen and (max-width: 767px) {
    .about-area {
      padding: 72px 0 45px;
    }
  }
  .about-area .text-pra {
    color: var(--black);
    font-size: 36px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.1px;
    margin: 0 auto 127px;
    max-width: 830px;
    padding-right: 50px;
  }
  @media only screen and (max-width: 1199px) {
    .about-area .text-pra {
      margin-bottom: 85px;
    }
  }
  @media only screen and (max-width: 767px) {
    .about-area .text-pra {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 50px;
      padding-right: 0;
    }
  }
  .about-area .service-list-content {
    margin: 0 auto 62px;
    max-width: 830px;
  }
  @media only screen and (max-width: 1199px) {
    .about-area .service-list-content {
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    .about-area .service-list-content {
      margin-bottom: 20px;
    }
  }
  .about-area .service-list-content .title {
    margin-bottom: 41px;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 767px) {
    .about-area .service-list-content .title {
      margin-bottom: 30px;
    }
  }
  .about-area .service-list-content p {
    color: var(--grey1);
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 69px;
  }
  @media only screen and (max-width: 767px) {
    .about-area .service-list-content p {
      margin-bottom: 40px;
    }
  }
  .about-area .service-list-content p.text-block {
    color: var(--black);
    font-size: 16px;
    margin-bottom: 31px;
  }
  .about-area .service-list-content .service-list {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 479.98px) {
    .about-area .service-list-content .service-list {
      display: block;
      padding-bottom: 20px;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .about-area .service-list-content .service-list ul {
      margin-bottom: 0;
    }
  }
  .about-area .service-list-content .service-list ul li {
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 767px) {
    .about-area .service-list-content .service-list ul li {
      margin-bottom: 18px;
    }
  }
  .about-area .service-list-content .service-list ul li a {
    color: var(--black);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 767px) {
    .about-area .service-list-content .service-list ul li a {
      font-size: 14px;
    }
  }
  .about-area .service-list-content .service-list ul li a:hover {
    color: var(--primary);
  }
  .about-area .office-center-content .title {
    margin: 0 auto 40px;
    max-width: 830px;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 767px) {
    .about-area .office-center-content .title {
      margin-bottom: 20px;
    }
  }
  .about-area .office-center-content p {
    color: var(--grey1);
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 auto 59px;
    max-width: 830px;
  }
  @media only screen and (max-width: 767px) {
    .about-area .office-center-content p {
      margin-bottom: 20px;
    }
  }
  .about-area .office-center-content .office-address-content {
    display: flex;
    justify-content: space-between;
    margin: 135px auto 0;
    max-width: 830px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 1199px) {
    .about-area .office-center-content .office-address-content {
      margin-top: 95px;
    }
  }
  @media only screen and (max-width: 767px) {
    .about-area .office-center-content .office-address-content {
      margin-top: 80px;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .about-area .office-center-content .office-address-content {
      display: block;
      margin-top: 65px;
    }
  }
  .about-area .office-center-content .office-address-content .office-address-item h5 {
    font-size: 18px;
    letter-spacing: -0.21px;
    margin-bottom: 23px;
    text-transform: uppercase;
  }
  .about-area .office-center-content .office-address-content .office-address-item ul {
    margin-bottom: 30px;
  }
  .about-area .office-center-content .office-address-content .office-address-item ul li {
    color: var(--grey1);
    font-size: 16px;
    margin-bottom: 3px;
  }
  .about-area .office-center-content .office-address-content .office-address-item ul li:last-child {
    margin-bottom: 0;
  }
  .contact .content .text .copy {
    border-top:1px solid #a8a8a8;
    margin-top: 30px;
    padding-top: 20px;
    color:var(--grey-alt);
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    line-height: 30px;
  }

/*
-----------------------------------------------------------------------
	Blog CSS
-----------------------------------------------------------------------
*/

.apropos .post-item {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .apropos .post-item {
    display: block;
    margin: 2px;
  }
}

.apropos .post-item .content .title {
  font-weight: 200;
  line-height: 38px;
  letter-spacing: 1px;
  margin-bottom: 21px;
  padding: 13px 3px 10px;
}

@media only screen and (max-width: 1199px) {
  .apropos .post-item .content .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .apropos .post-item .content .title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -7px;
    font-family: 'Lato', sans-serif;
    text-align: justify;
  }
}

/* Sous-Titres================================= */

.apropos .post-item .content .title a {
  font-size: 16px;
  line-height: 30px;
  color: var(--grey-alt);
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.apropos .post-item .content .title ul{
  font-size: 16px;
  line-height: 30px;
  color: var(--grey-alt);
  margin: 0;
  font-family: 'Lato', sans-serif;
  margin-left: 49px;
  padding-top: 12px;
  line-height: 30px;
  text-align: justify;
}

@media only screen and (max-width: 1199px){
  .apropos .post-item .content .title a {
    font-size: 16px;
    line-height: 30px;
    color: var(--grey-alt);
    margin: 0;
    font-family: 'Lato', sans-serif;
  }
}

@media only screen and (max-width: 767px){
  .apropos .post-item .content .title a {
      font-size: 16px;
      line-height: 30px;
      color: var(--grey-alt);
      margin: 0;
      font-family: 'Lato', sans-serif;
  }

  .apropos .post-item .content .title ul{
      font-size: 16px;
      line-height: 30px;
      color: var(--grey-alt);
      margin: 0;
      font-family: 'Lato', sans-serif;
      margin-left: 49px;
      padding-top: 12px;
      line-height: 30px;
  }
}

