
/*
-----------------------------------------------------------------------
	Feature CSS
-----------------------------------------------------------------------
*/
.feature-area.feature-default-area {
    padding: 0 0 74px;
    position: relative;
  }
  @media only screen and (max-width: 1199px) {
    .feature-area.feature-default-area {
      padding: 100px 0 35px;
    }
  }
  @media only screen and (max-width: 767px) {
    .feature-area.feature-default-area {
      padding: 80px 0 15px;
    }
  }
  @media only screen and (max-width: 575px) {
    .feature-area.feature-default-area {
      padding: 70px 0 5px;
    }
  }
  .feature-area.feature-default-area .section-title {
    margin-bottom: 94px;
  }
  @media only screen and (max-width: 1199px) {
    .feature-area.feature-default-area .section-title {
      margin-bottom: 70px;
    }
  }
  @media only screen and (max-width: 767px) {
    .feature-area.feature-default-area .section-title {
      margin-bottom: 60px;
    }
  }
  @media only screen and (max-width: 575px) {
    .feature-area.feature-default-area .section-title {
      margin-bottom: 50px;
    }
  }
  .feature-area.feature-default-area .thumb {
    bottom: 0;
    left: calc(50% + 253px);
    position: absolute;
  }
  @media only screen and (max-width: 1399px) {
    .feature-area.feature-default-area .thumb {
      left: calc(50% + 150px);
    }
  }
  @media only screen and (max-width: 1199px) {
    .feature-area.feature-default-area .thumb {
      display: none;
    }
  }
  .feature-area.feature-default-area .thumb img {
    max-width: none;
    height: 1000px;
  }
  
  .icon-box-style1 .icon-box-item .icon img {
     max-width:60px;
  }