a {
    color: rgb(17, 17, 17);
}

.services {
	padding: 10px 0px;
	background: rgb(255, 255, 255);
    margin: 0px;
    height: 100%;
}

.services .contents .box {
	flex: 0 0 50%;
    padding: 2px;
    margin-top: 2px;
}

.services .contents .text h2 {
	font-size: 28px;
    font-weight: 900;
    color: var(--primary);
    margin-top: 13px;
    margin-left: 15px;
}

.services .contents .log-sign {
    flex: initial;
    width: 100%;
    text-align: center;
    justify-content: flex-start;
    bottom: 16px;
    position: absolute;
}

.button-identification {
	border: none;
    outline: none;
    font-weight: 900;
    box-shadow: none;
    height: 45px;
    padding: 0px 50px;
    border: 1px solid transparent;
	background-color:var(--primary);
	/* border: 1px solid rgb(255, 255, 255); */
    color: var(--white);
    font-size: 15px;
    transition: all .5s ease;
    cursor: pointer;
    border-radius: 0px;
    /* text-transform: lowercase; */
}

.button-identification a {
    color: var(--white);
}

.button-inscription {
	border: none;
    outline: none;
    font-weight: 900;
    box-shadow: none;
    height: 45px;
    padding: 0px 50px;
    border: 1px solid rgb(206 19 19);
    color:#fff;
    background-color: #000000;
    font-size: 15px;
    transition: all .5s ease;
    cursor: pointer;
    border-radius: 0px;
}

.button-inscription a {
    color: var(--white);
}



.button:cl{
	background-color:transparent;
	color:var(--white); 
    border-color:var(--white);
}

ul.acorh {
	margin: 10px auto;
	padding: 0;
	list-style: none;
	width: 100%;
	font-size: 18px;
}

ul.acorh li {
	list-style: none;
}

ul.acorh li a {
    display: block;
    padding: 14px 10px 12px 15px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-top: 1px solid rgb(248, 246, 246);
    text-decoration: none;
    box-sizing: border-box;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
}

ul.acorh li ul {
	max-height: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	transition: .3s all ease-in;
}

ul.acorh li li a {
    padding: 2px 10px 6px 17px;
    color: rgb(0, 0, 0);
    font-size: 13px;
    border: 0;
    box-sizing: border-box;
    text-transform: none;
    padding-top: 5px;
}

ul.acorc li li:last-child a {
	border-bottom: 0;
}

ul.acorh li:hover ul {
	max-height: 300px;
	transition: .3s all ease-in;
}

/* ul.acorh li a:hover {
	background: rgb(255, 255, 255);
	color: #fff;
} */
