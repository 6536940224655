/*
-----------------------------------------------------------------------
  Header CSS
-----------------------------------------------------------------------
*/ 
  .header-area2 {
    padding: 50px 0;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2 .container {
      max-width: none;
      width: 100%;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2 {
      padding: 53px 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2 {
      padding: 25px 0;
    }
  }
  .header-area2.sticky-header {
    transition: 0.4s;
  }
  .header-area2.sticky-header.sticky {
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    border: 0 !important;
    position: fixed;
    left: 0;
    top: 0;
    padding: 25px 0;
    width: 100%;
    z-index: 99;
  }
  
  .header-area2.sticky-header.sticky .header-logo-area .logo-main {
    display: none;
  }
  .header-area2.sticky-header.sticky .header-logo-area .logo-light {
    display: block;
  }

  .header-area2.sticky-header.sticky .main-menu > li:hover > a {
    background-color: #111;
    color: var(--white);
  }
  .header-area2.sticky-header.sticky .main-menu > li:hover > a:after {
    opacity: 1;
  }
  .header-area2.sticky-header.sticky .main-menu > li > a {
    color: var(--white);
  }
  .header-area2.sticky-header.sticky .main-menu > li > a:after {
    background-color: #111;
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    right: -14px;
    top: 0;
    width: 14px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .header-area2.sticky-header.sticky .main-menu > li > a:hover {
    background-color: #111;
  }
  .header-area2.sticky-header.sticky .main-menu > li > a:hover:after {
    opacity: 1;
  }
  .header-area2.sticky-header.sticky .main-menu > li > a:before {
    color: var(--white);
  }
  .header-area2.sticky-header.sticky .header-action-area button, .header-area2.sticky-header.sticky .header-action-area a {
    color: var(--white);
  }
  .header-area2.sticky-header.sticky .header-action-area button:hover, .header-area2.sticky-header.sticky .header-action-area a:hover {
    background-color: #111;
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-cart + .header-shopping-cart, .header-area2.sticky-header.sticky .header-action-area a.btn-cart + .header-shopping-cart {
    top: calc(100% + 45px);
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a, .header-area2.sticky-header.sticky .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a {
    background-color: transparent;
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a:hover, .header-area2.sticky-header.sticky .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a:hover {
    background-color: transparent;
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-cart + .header-shopping-cart:hover, .header-area2.sticky-header.sticky .header-action-area a.btn-cart + .header-shopping-cart:hover {
    opacity: 1;
    top: calc(100% + 25px);
    visibility: visible;
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-cart:hover + .header-shopping-cart, .header-area2.sticky-header.sticky .header-action-area a.btn-cart:hover + .header-shopping-cart {
    top: calc(100% + 25px);
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-menu, .header-area2.sticky-header.sticky .header-action-area a.btn-menu {
    background-color: transparent;
  }
  .header-area2.sticky-header.sticky .header-action-area button.btn-menu:hover, .header-area2.sticky-header.sticky .header-action-area a.btn-menu:hover {
    background-color: transparent;
  }
  .header-area2.sticky-header.sticky .header-action-area .btn-search-content {
    bottom: -145px;
  }
  .header-area2.sticky-header.sticky .header-action-area .btn-search-content.show {
    bottom: -125px;
  }

  .header-area2.sticky-header.sticky .header-action-area .btn-menu span {
    background-color: var(--black);
  }

  .header-area2.transparent:not(.sticky) {
    color: var(--white);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
  }

  .header-area2.transparent:not(.sticky) .header-logo-area .logo-main {
    display: none;
  }
  .header-area2.transparent:not(.sticky) .header-logo-area .logo-light {
    display: block;
  }
  .header-area2.transparent:not(.sticky) .main-menu > li:hover > a {
    background-color: #111;
    color: var(--white);
  }
  .header-area2.transparent:not(.sticky) .main-menu > li:hover > a:after {
    opacity: 1;
  }
  .header-area2.transparent:not(.sticky) .main-menu > li > a {
    color: var(--white);
  }
  .header-area2.transparent:not(.sticky) .main-menu > li > a:after {
    background-color: #111;
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    right: -14px;
    top: 0;
    width: 14px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .header-area2.transparent:not(.sticky) .main-menu > li > a:hover {
    background-color: #111;
  }
  .header-area2.transparent:not(.sticky) .main-menu > li > a:hover:after {
    opacity: 1;
  }
  .header-area2.transparent:not(.sticky) .main-menu > li > a:before {
    color: var(--white);
  }
  .header-area2.transparent:not(.sticky) .header-action-area button, .header-area2.transparent:not(.sticky) .header-action-area a {
    color: var(--white);
  }
  .header-area2.transparent:not(.sticky) .header-action-area button:hover, .header-area2.transparent:not(.sticky) .header-action-area a:hover {
    background-color: #111;
  }
  .header-area2.transparent:not(.sticky) .header-action-area .btn-menu {
    background-color: transparent;
  }
  .header-area2.transparent:not(.sticky) .header-action-area .btn-menu span {
    background-color: var(--black);
  }
  .header-area2.transparent:not(.sticky) .header-action-area .btn-menu:hover {
    background-color: transparent;
  }
  .header-area2.transparent:not(.sticky) .header-action-area .btn-menu:hover span {
    background-color: var(--primary);
  }
  .header-area2.fixed-left {
    background-color: var(--white);
    box-shadow: none;
    padding: 80px 40px 50px 60px;
    position: fixed;
    height: 100vh;
    max-width: 320px;
    width: 100%;
    z-index: 8;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.fixed-left {
      padding: 50px 40px 50px 60px;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left {
      background-color: var(--white);
      height: auto;
      max-width: none;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-left {
      padding: 30px 15px;
    }
  }
  .header-area2.fixed-left .header-left-fix-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left .header-left-fix-inner {
      flex-direction: row;
      height: auto;
    }
  }
  .header-area2.fixed-left .header-logo-area {
    margin-left: -20px;
    margin-bottom: 40px;
    max-width: 100%;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left .header-logo-area {
      margin-bottom: 0;
      margin-top: 7px;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-left .header-logo-area {
      margin-left: 0;
    }
  }
  .header-area2.fixed-left .header-logo-area a {
    max-width: 148px;
    display: block;
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-left .header-logo-area a {
      max-width: 120px;
    }
  }
  .header-area2.fixed-left .header-navigation-area {
    margin: 10px -60px;
  }
  .header-area2.fixed-left .header-navigation-area .nav {
    display: block;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li {
    margin-right: 0;
    margin-bottom: 0;
    padding-right: 30px;
    padding-left: 15px;
    cursor: pointer;
    position: relative;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li.has-submenu > a:before {
    display: none;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li:last-child {
    margin-bottom: 0;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li a {
    display: inline-block;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav {
    left: 100%;
    margin-top: -20px;
    top: 0;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav:before {
    display: none;
  }
  .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav-mega {
    margin-left: 0;
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav-mega {
      width: 860px;
    }
  }
  @media screen and (min-width: 1400px) {
    .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav-mega {
      width: 1050px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .header-area2.fixed-left .header-navigation-area .nav > li .submenu-nav-mega .mega-menu-item {
      padding: 25px !important;
    }
  }
  .header-area2.fixed-left .widget-search-box {
    margin-bottom: 49px;
    margin-left: -20px;
  }
  .header-area2.fixed-left .widget-search-box .form-input-item input {
    border: 1px solid var(--grey2);
    border-radius: 20px;
    font-size: 12px;
    height: 42px;
    padding: 5px 35px 5px 20px;
  }
  .header-area2.fixed-left .widget-search-box .form-input-item .btn-src {
    font-size: 14px;
    top: 55%;
  }
  .header-area2.fixed-left .widget-contact-info {
    margin-bottom: 0;
    margin-left: -20px;
  }
  .header-area2.fixed-left .widget-contact-info li {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .header-area2.fixed-left .widget-contact-info li i {
    color: var(--grey2);
    font-size: 14px;
    display: inline-block;
    line-height: 1.72;
    margin-right: 0;
    width: 25px;
  }
  .header-area2.fixed-left .widget-contact-info li i.fa-map-marker {
    width: 34px;
  }
  .header-area2.fixed-left .widget-contact-info li span {
    color: var(--grey2);
    font-size: 14px;
  }
  .header-area2.fixed-left .widget-contact-info li:first-child {
    margin-top: 0;
  }
  .header-area2.fixed-left .widget-social-icons {
    margin-left: -20px;
    margin-top: 30px;
  }
  .header-area2.fixed-left .widget-social-icons a {
    color: var(--grey2);
    margin-left: 36px;
  }
  .header-area2.fixed-left .widget-social-icons a:first-child {
    margin-left: 0;
  }
  .header-area2.fixed-left .header-widget-area {
    font-size: 0.875rem;
  }
  .header-area2.fixed-left .header-widget-area .copyright-content {
    margin-top: 40px;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left .header-action-area .btn-search-content {
      bottom: -120px;
      right: 30px;
    }
    .header-area2.fixed-left .header-action-area .btn-search-content.show {
      bottom: -100px;
    }
  }
  .header-area2.fixed-left.transparet {
    background-color: transparent;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left.transparet {
      background-color: var(--white);
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-left.transparet .header-logo-area a .logo-light {
      display: block;
    }
    .header-area2.fixed-left.transparet .header-logo-area a .logo-main {
      display: none;
    }
  }

  .header-area2.fixed-left.transparet .header-navigation-area .nav > li > a {
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item input {
    border: 1px solid var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-search-box .form-input-item .btn-src {
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-contact-info li i {
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-contact-info li span {
    color: var(--white);
  }
  .header-area2.fixed-left.transparet .widget-social-icons a {
    color: var(--white);
  }
  .header-area2.fixed-right {
    background-color: var(--white);
    box-shadow: none;
    padding: 80px 40px 60px 60px;
    position: fixed;
    height: 100vh;
    max-width: 320px;
    right: 0;
    width: 100%;
    z-index: 9999;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.fixed-right {
      padding: 50px 40px 50px 60px;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-right {
      background-color: var(--white);
      height: auto;
      max-width: none;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-right {
      padding: 30px 15px;
    }
  }
  .header-area2.fixed-right .header-right-fix-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-right .header-right-fix-inner {
      flex-direction: row;
      height: auto;
    }
  }
  .header-area2.fixed-right .header-logo-area {
    margin-left: -20px;
    margin-bottom: 40px;
    max-width: 100%;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-right .header-logo-area {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-right .header-logo-area {
      margin-left: 0;
    }
  }
  .header-area2.fixed-right .header-logo-area a {
    max-width: 148px;
    display: block;
  }
  @media only screen and (max-width: 767px) {
    .header-area2.fixed-right .header-logo-area a {
      max-width: 120px;
    }
  }
  .header-area2.fixed-right .header-navigation-area {
    margin: 0 -60px;
  }
  .header-area2.fixed-right .header-navigation-area .nav {
    display: block;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li {
    margin-right: 0;
    margin-bottom: 0;
    padding-right: 30px;
    padding-left: 15px;
    cursor: pointer;
    position: relative;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li.has-submenu > a:before {
    display: none;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li:last-child {
    margin-bottom: 0;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li a {
    display: inline-block;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav {
    left: auto;
    margin-top: 0;
    right: 100%;
    top: 0;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav .has-submenu .submenu-nav {
    left: 100%;
    margin-top: 0;
    right: auto;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav:before {
    display: none;
  }
  .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav-mega {
    margin-left: 0;
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav-mega {
      width: 860px;
    }
  }
  @media screen and (min-width: 1400px) {
    .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav-mega {
      width: 1050px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .header-area2.fixed-right .header-navigation-area .nav > li .submenu-nav-mega .mega-menu-item {
      padding: 25px !important;
    }
  }
  .header-area2.fixed-right .widget-contact-info {
    margin-bottom: 0;
    margin-left: -20px;
  }
  .header-area2.fixed-right .widget-contact-info li {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .header-area2.fixed-right .widget-contact-info li i {
    color: var(--grey2);
    font-size: 14px;
    display: inline-block;
    line-height: 1.72;
    margin-right: 0;
    width: 25px;
  }
  .header-area2.fixed-right .widget-contact-info li i.fa-map-marker {
    width: 34px;
  }
  .header-area2.fixed-right .widget-contact-info li span {
    color: var(--grey2);
    font-size: 14px;
  }
  .header-area2.fixed-right .widget-contact-info li:first-child {
    margin-top: 0;
  }
  .header-area2.fixed-right .widget-social-icons {
    margin-left: -20px;
    margin-top: 30px;
  }
  .header-area2.fixed-right .widget-social-icons a {
    color: var(--grey2);
    margin-left: 36px;
  }
  .header-area2.fixed-right .widget-social-icons a:first-child {
    margin-left: 0;
  }
  .header-area2.fixed-right .header-widget-area {
    font-size: 0.875rem;
  }
  .header-area2.fixed-right .header-widget-area .copyright-content {
    margin-top: 40px;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-right .header-action-area .btn-search-content {
      bottom: -120px;
      right: 30px;
    }
    .header-area2.fixed-right .header-action-area .btn-search-content.show {
      bottom: -100px;
    }
  }
  .header-area2.fixed-right.header-right-slide {
    right: -100%;
    transform: trnslateX(100%);
  }
  @media only screen and (max-width: 991px) {
    .header-area2.fixed-right.header-right-slide {
      background-color: var(--white);
      height: auto;
      max-width: none;
      right: auto;
      width: 100%;
    }
  }
  .header-area2.fixed-right.header-right-slide.active {
    right: 0;
    transform: none;
    transition-delay: 0.1s;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header {
    color: var(--white);
    display: block;
    left: 0;
    padding: 59px 0 50px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header.sticky {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: none;
    border: 0 !important;
    position: fixed;
    left: 0;
    top: 0;
    padding: 25px 0;
    width: 100%;
    z-index: 99;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-top-slide-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-logo-area {
    max-width: 205px;
    top: 2px;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: 18px;
    margin-left: 16px;
    margin-top: 0;
    position: relative;
    width: 35px;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide span, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide span {
    background-color: #303744;
    border-radius: 2px;
    display: block;
    height: 4px;
    position: absolute;
    right: 0;
    transition: 0.4s;
    width: 100%;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide span:first-child, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide span:first-child {
    top: 0;
    width: 30px;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide span:nth-child(2), .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide span:last-child, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide span:last-child {
    bottom: 0;
    width: 25px;
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide:hover span, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide:hover span {
    background-color: var(--primary);
  }
  .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide:hover span:first-child, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area button.btn-menu-slide:hover span:last-child, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide:hover span:first-child, .header-area2.fixed-right.header-right-slide + .header-top-slide.sticky-header .header-action-area a.btn-menu-slide:hover span:last-child {
    width: 100%;
  }
  @media (min-width: 992px) {
    .header-area2.fixed-right.header-right-slide.sticky-header.sticky {
      background-color: var(--white);
      left: auto;
      padding: 80px 40px 60px 60px;
      z-index: 9999;
    }
    .header-area2.fixed-right.header-right-slide.sticky-header.sticky .main-menu > li > a:hover {
      background-color: transparent;
    }
    .header-area2.fixed-right.header-right-slide.sticky-header.sticky .main-menu > li > a:after {
      display: none;
    }
    .header-area2.fixed-right.header-right-slide.sticky-header.sticky .main-menu > li:hover a {
      background-color: transparent;
    }
  }
  .header-area2.header-right-align .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1240px) {
    .header-area2.header-right-align .header-right {
      justify-content: flex-end;
    }
  }
  .header-area2.header-right-align .header-right .header-action-area {
    margin-left: 42px;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.header-right-align .header-right .header-navigation-area {
      display: none;
    }
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu .submenu-nav {
    min-width: 220px;
    left: 0;
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu .submenu-nav .submenu-nav {
    left: 100%;
    top: 15px;
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.full-width .submenu-nav.submenu-nav-mega {
    left: -195px;
    min-width: 1170px;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.full-width .submenu-nav.submenu-nav-mega {
      left: auto;
      min-width: 962px;
      right: 0;
    }
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.colunm-two {
    position: relative;
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.colunm-two .submenu-nav-mega {
    left: -250px;
    width: 600px;
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.colunm-two .submenu-nav-mega .mega-menu-item {
    padding: 0 !important;
    flex-basis: 50%;
  }
  .header-area2.header-right-align .header-right .header-navigation-area .has-submenu.colunm-two.colunm-style-two .submenu-nav-mega {
    left: -446px;
    width: 800px;
  }
  .header-area2.header-right-align.header-area2.sticky-header.sticky .main-menu > li > .submenu-nav {
    margin-top: 25px;
  }
  .header-area2.header-right-align .container-fluid .header-right .header-navigation-area .has-submenu.full-width .submenu-nav.submenu-nav-mega {
    left: auto;
    max-width: 1170px;
    right: 115px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .header-area2.header-right-align .container-fluid .header-right .header-navigation-area .has-submenu.full-width .submenu-nav.submenu-nav-mega {
      right: -25px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-right-align .container-fluid .header-right .header-navigation-area .has-submenu.full-width .submenu-nav.submenu-nav-mega {
      left: auto;
      min-width: 962px;
      right: 0;
    }
  }
  .header-area2.header-default {
    background-color: var(--white);
    padding: 50px 0 42px;
    position: relative;
    z-index: 999;
  }
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default {
      padding: 40px 0;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.header-default {
      padding: 25px 0 22px;
    }
  }
  .header-area2.header-default:after {
    background-color: #e4dcdc;
    bottom: 0;
    content: "";
    height: 2px;
    left: 70px;
    position: absolute;
    width: calc(100% - 140px);
  }
  
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default:after {
      left: 30px;
      width: calc(100% - 60px);
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.header-default:after {
      left: 15px;
      width: calc(100% - 30px);
    }
  }
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default .container {
      max-width: none;
      padding: 0 30px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .header-area2.header-default .container {
      padding: 0 15px;
    }
  }
  .header-area2.header-default .header-align {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .header-area2.header-default .header-align .header-logo-area {
    left: 85px;
    position: absolute;
    top: calc(50% - 3px);
    transform: translateY(-50%);
  }
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default .header-align .header-logo-area {
      left: auto;
      position: relative;
      top: auto;
      transform: none;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.header-default .header-align .header-navigation-area {
      display: none;
    }
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li {
    margin-right: 0;
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li:first-child > a {
    padding-left: 0;
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li:last-child {
    margin-right: 0;
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li:last-child > a {
    padding-right: 0;
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li > a {
    padding: 7px 17.55px;
  }
  .header-area2.header-default .header-align .header-navigation-area .main-menu > li.has-submenu > a:before {
    display: none;
  }
  .header-area2.header-default .header-align .header-action-area {
    align-items: center;
    display: inline-flex;
    position: absolute;
    right: 70px;
  }
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default .header-align .header-action-area {
      position: relative;
      right: auto;
    }
  }
  .header-area2.header-default .header-align .header-action-area .login-reg {
    align-items: center;
    color: var(--black);
    display: flex;
    font-weight: 700;
  }
  .header-area2.header-default .header-align .header-action-area .login-reg a {
    color: var(--black);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-left: 0;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 479.98px) {
    .header-area2.header-default .header-align .header-action-area .login-reg a {
      display: none;
    }
  }
  .header-area2.header-default .header-align .header-action-area .login-reg a:hover {
    color: var(--primary);
  }
  .header-area2.header-default .header-align .header-action-area .login-reg span {
    display: inline-block;
    margin: 0 5px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-default .header-align .header-action-area .login-reg span {
      margin: 0 3px;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .header-area2.header-default .header-align .header-action-area .login-reg span {
      display: none;
    }
  }
  .header-area2.header-default .header-align .header-action-area .login-reg .icon {
    font-size: 20px;
    margin-left: 14px;
    position: relative;
    top: -1px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-default .header-align .header-action-area .login-reg .icon {
      margin-left: 9px;
    }
  }
  @media only screen and (max-width: 479.98px) {
    .header-area2.header-default .header-align .header-action-area .login-reg .icon {
      cursor: pointer;
    }
  }
  .header-area2.header-default .header-align .header-action-area .widget-language {
    cursor: pointer;
    line-height: 1;
    margin-left: 71px;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-default .header-align .header-action-area .widget-language {
      margin-left: 20px;
    }
  }
  .header-area2.header-default .header-align .header-action-area .widget-language .current {
    color: var(--black);
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    position: relative;
    text-transform: uppercase;
    top: -3px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .header-area2.header-default .header-align .header-action-area .widget-language .current i {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-left: 1px;
    position: relative;
    right: -5px;
    top: 3px;
  }
  @media only screen and (max-width: 1199px) {
    .header-area2.header-default .header-align .header-action-area .widget-language .current i {
      margin-left: 3px;
    }
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul {
    bottom: -50px;
    margin-bottom: 0;
    min-width: 60px;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul:before {
    background-color: #111;
    content: "";
    height: calc(100% + 18px);
    left: -15px;
    position: absolute;
    top: -9px;
    width: calc(100% + 30px);
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul:after {
    background-color: transparent;
    content: "";
    height: 5px;
    left: -15px;
    position: absolute;
    top: -14px;
    width: calc(100% + 30px);
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul li {
    margin-bottom: 7px;
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul li:last-child {
    margin-bottom: 0;
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul li a {
    color: #aaa;
    display: block;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
  }
  .header-area2.header-default .header-align .header-action-area .widget-language ul li a:hover {
    color: var(--white);
  }
  .header-area2.header-default .header-align .header-action-area .widget-language:hover ul {
    bottom: -45px;
    opacity: 1;
    visibility: visible;
  }
  @media only screen and (max-width: 991px) {
    .header-area2.header-default .header-align .header-action-area button.btn-menu, .header-area2.header-default .header-align .header-action-area a.btn-menu {
      margin-left: 25px;
    }
  }
  @media only screen and (max-width: 991px) {
    .header-area2.header-default.sticky-header.sticky {
      padding: 15px 0;
    }
  }
  .header-area2.header-default.sticky-header.sticky:after {
    display: none;
  }
  
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li > a {
    color: var(--black);
  }

  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li > a:hover {
    background-color: transparent;
    color: var(--primary);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li:hover a {
    background-color: transparent;
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li:hover a:after {
    display: none;
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li.active > a {
    color: var(--primary);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li.has-submenu > a:before {
    display: none;
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li.has-submenu > .submenu-nav {
    margin-top: 24px;
  }
  @media only screen and (max-width: 1549px) {
    .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li.has-submenu > .submenu-nav {
      margin-top: 32px;
    }
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-navigation-area .main-menu > li.has-submenu > .submenu-nav:before {
    height: 44px;
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .login-reg a {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .login-reg a:hover {
    color: var(--primary);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .login-reg span, .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .login-reg .icon {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .widget-language .current {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .widget-language .current:hover {
    color: var(--primary);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .widget-language ul li a {
    background-color: transparent;
    color: var(--white);
  }
  .header-area2.header-default.sticky-header.sticky .header-align .header-action-area .widget-language ul li a:hover {
    color: var(--primary);
  }

  .header-area2.header-default.transparent {
     /* Test 1 */
    /* background-color: transparent; */
        /* background-color: transparent; */
          box-shadow: 0 8px 20px 0 rgb(0 0 0 / 10%);
          background-color: #ffffff;
  }

  .header-area2.header-default.transparent.sticky-header.sticky {
    /* Test 2 */
    background-color:#ffffff;
  }

  .header-area2.header-default.transparent:after {
    background-color:#ffffff;
  }

  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li > a {
    color: var(--black);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-left: 0;
    text-transform: uppercase;
  }

  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li > a:hover {
    background-color: transparent;
    color: var(--primary);
  }
  
  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li:hover a {
    background-color: transparent;
  }
  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li:hover a:after {
    display: none;
  }
  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li.active > a {
    color: var(--primary);
  }
  .header-area2.header-default.transparent .header-align .header-navigation-area .main-menu > li.has-submenu > a:before {
    display: none;
  }
  .header-area2.header-default.transparent .header-align .header-action-area .login-reg a {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .login-reg a:hover {
    color: var(--primary);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .login-reg span, .header-area2.header-default.transparent .header-align .header-action-area .login-reg .icon {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .widget-language .current {
    background-color: transparent;
    color: var(--black);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .widget-language .current:hover {
    color: var(--primary);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .widget-language ul li a {
    background-color: transparent;
    color: var(--white);
  }
  .header-area2.header-default.transparent .header-align .header-action-area .widget-language ul li a:hover {
    color: var(--primary);
  }
  .header-area2.header-default.header-style-two:after {
    display: none;
  }
  
  .btn-menu-slide-close {
    background-color: rgba(33, 35, 49, 0.8);
    height: 100vh;
    opacity: 0;
    position: fixed;
    right: -50%;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 999;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .btn-menu-slide-close .icon-menu-slide-close {
    color: var(--white);
    font-size: 32px;
    position: absolute;
    right: 360px;
    top: 45px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .btn-menu-slide-close .icon-menu-slide-close:hover {
    color: var(--primary);
    cursor: pointer;
  }
  .btn-menu-slide-close.active {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .btn-menu-slide-close.active .icon-menu-slide-close {
    transition-delay: 0.3s;
  }
  
  .header-logo-area {
    max-width: 148px;
    position: relative;
    top: 1px;
  }
  .header-logo-area .logo-light {
    display: none;
  }
  
  .header-action-area button, .header-action-area a {
    color: var(--grey2);
    font-size: 14px;
    line-height: 1;
    margin-left: 15px;
    vertical-align: middle;
  }
  .header-action-area button:first-child, .header-action-area a:first-child {
    margin-left: 0;
  }
  .header-action-area button.btn-search, .header-action-area a.btn-search {
    background-color: transparent;
    border: none;
    display: inline-block;
    height: 44px;
    font-size: 18px;
    margin: 0;
    padding: 8.5px 0 5.5px;
    position: relative;
    text-align: center;
    width: 44px;
  }
  .header-action-area button.btn-search .icon-search, .header-action-area a.btn-search .icon-search {
    display: block;
  }
  .header-action-area button.btn-search .icon-search-close, .header-action-area a.btn-search .icon-search-close {
    display: none;
  }
  .header-action-area button.btn-search.show .icon-search, .header-action-area a.btn-search.show .icon-search {
    display: none;
  }
  .header-action-area button.btn-search.show .icon-search-close, .header-action-area a.btn-search.show .icon-search-close {
    color: var(--primary);
    display: block;
  }
  .header-action-area button.btn-search:hover, .header-action-area a.btn-search:hover {
    color: var(--primary);
  }
  .header-action-area button.btn-cart, .header-action-area a.btn-cart {
    background-color: transparent;
    border: none;
    display: inline-block;
    height: 44px;
    font-size: 18px;
    margin: 0;
    padding: 5.5px 0;
    position: relative;
    text-align: center;
    width: 37px;
  }
  .header-action-area button.btn-cart:after, .header-action-area a.btn-cart:after {
    top: 37px;
    content: "";
    height: 75px;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    right: -32px;
    width: calc(100% + 280px);
  }
  .header-action-area button.btn-cart:hover .btn-cart-icon, .header-action-area a.btn-cart:hover .btn-cart-icon {
    color: var(--primary);
  }
  .header-action-area button.btn-cart:hover:after, .header-action-area a.btn-cart:hover:after {
    opacity: 1;
    pointer-events: visible;
  }
  .header-action-area button.btn-cart:hover + .header-shopping-cart, .header-action-area a.btn-cart:hover + .header-shopping-cart {
    opacity: 1;
    top: calc(100% + 50px);
    visibility: visible;
  }
  @media only screen and (max-width: 767px) {
    .header-action-area button.btn-cart:hover + .header-shopping-cart, .header-action-area a.btn-cart:hover + .header-shopping-cart {
      top: calc(100% + 25px);
    }
  }
  .header-action-area button.btn-cart + .header-shopping-cart, .header-action-area a.btn-cart + .header-shopping-cart {
    background-color: var(--black);
    color: #aaa;
    font-size: 14px;
    line-height: 24px;
    opacity: 0;
    overflow: hidden;
    padding: 20px 30px;
    position: absolute;
    right: 15px;
    top: calc(100% + 70px);
    visibility: hidden;
    width: 320px;
    z-index: 9999;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
  }
  @media only screen and (max-width: 767px) {
    .header-action-area button.btn-cart + .header-shopping-cart, .header-action-area a.btn-cart + .header-shopping-cart {
      top: calc(100% + 45px);
    }
  }
  @media (max-width: 379px) {
    .header-action-area button.btn-cart + .header-shopping-cart, .header-action-area a.btn-cart + .header-shopping-cart {
      right: 0;
    }
  }
  @media (max-width: 345px) {
    .header-action-area button.btn-cart + .header-shopping-cart, .header-action-area a.btn-cart + .header-shopping-cart {
      right: -12px;
    }
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product {
    margin-bottom: 0;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item {
    background-color: transparent;
    display: inline-block;
    margin-bottom: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:after, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:after {
    color: #aaa;
    content: "x";
    cursor: pointer;
    font-size: 13px;
    line-height: 1;
    pointer-events: visible;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:last-child, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:last-child {
    margin-bottom: 0;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a {
    color: #aaa;
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a img, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a img {
    float: left;
    max-width: 70px;
    margin-right: 15px;
    padding-top: 6px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a:hover, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item a:hover {
    color: var(--white);
    background-color: transparent;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item .quantity-price, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item .quantity-price {
    font-size: 14px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:hover:after, .header-action-area a.btn-cart + .header-shopping-cart .widget-shopping-cart-content .cart-list-product .mini-cart-item:hover:after {
    color: var(--white);
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-total, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-total {
    border-top: 1px solid rgba(221, 221, 221, 0.3);
    line-height: 40px;
    padding-top: 10px;
    margin: 20px 0 10px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-total strong, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-total strong {
    text-transform: uppercase;
    font-weight: 600;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-total .price-amount-total, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-total .price-amount-total {
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    float: right;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-buttons, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-buttons {
    margin-bottom: 10px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-buttons a, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-buttons a {
    background-color: var(--white);
    border: none;
    color: var(--black);
    display: inline-block;
    font-size: 14px;
    height: 44px;
    line-height: 44px;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    width: 125px;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-buttons a:last-child, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-buttons a:last-child {
    float: right;
    margin: 0;
  }
  .header-action-area button.btn-cart + .header-shopping-cart .mini-cart-buttons a:hover, .header-action-area a.btn-cart + .header-shopping-cart .mini-cart-buttons a:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  .header-action-area button.btn-cart + .header-shopping-cart:hover, .header-action-area a.btn-cart + .header-shopping-cart:hover {
    opacity: 1;
    top: calc(100% + 50px);
    visibility: visible;
  }
  @media only screen and (max-width: 767px) {
    .header-action-area button.btn-cart + .header-shopping-cart:hover, .header-action-area a.btn-cart + .header-shopping-cart:hover {
      top: calc(100% + 25px);
    }
  }
  .header-action-area button.btn-menu, .header-action-area a.btn-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: 18px;
    margin-left: 16px;
    margin-top: 0;
    position: relative;
    width: 35px;
  }
  .header-action-area button.btn-menu span, .header-action-area a.btn-menu span {
    background-color: #303744;
    border-radius: 2px;
    display: block;
    height: 4px;
    position: absolute;
    right: 0;
    transition: 0.4s;
    width: 100%;
  }
  .header-action-area button.btn-menu span:first-child, .header-action-area a.btn-menu span:first-child {
    top: 0;
    width: 30px;
  }
  .header-action-area button.btn-menu span:nth-child(2), .header-action-area a.btn-menu span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .header-action-area button.btn-menu span:last-child, .header-action-area a.btn-menu span:last-child {
    bottom: 0;
    width: 25px;
  }
  .header-action-area button.btn-menu:hover span, .header-action-area a.btn-menu:hover span {
    background-color: var(--primary);
  }
  .header-action-area button.btn-menu:hover span:first-child, .header-action-area button.btn-menu:hover span:last-child, .header-action-area a.btn-menu:hover span:first-child, .header-action-area a.btn-menu:hover span:last-child {
    width: 100%;
  }
  .header-action-area button.btn-theme, .header-action-area a.btn-theme {
    background-color: var(--primary);
    font-size: 16px;
    line-height: 1;
    min-height: auto;
    border-radius: 30px;
    padding: 17px 60px;
  }
  @media only screen and (max-width: 1199px) {
    .header-action-area button.btn-theme, .header-action-area a.btn-theme {
      font-size: 14px;
      border-radius: 25px;
      padding: 12px 25px;
    }
  }
  .header-action-area .btn-search-content {
    bottom: -170px;
    opacity: 0;
    position: absolute;
    right: 0;
    visibility: hidden;
    z-index: 9999;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .header-action-area .btn-search-content form {
    background-color: #111;
    box-shadow: 0px 20px 80px 0px rgba(171, 181, 189, 0.35);
    border-radius: 0 0 5px 5px;
    padding: 25px 20px;
    width: 300px;
  }
  .header-action-area .btn-search-content form .form-input-item {
    position: relative;
  }
  .header-action-area .btn-search-content form .form-input-item input {
    background-color: var(--black);
    border: none;
    border-radius: 5px;
    color: var(--white);
    height: 50px;
    padding: 7px 45px 7px 20px;
    width: 100%;
  }
  .header-action-area .btn-search-content form .form-input-item input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #aba5a5;
    font-size: 14px;
  }
  .header-action-area .btn-search-content form .form-input-item input::-moz-placeholder {
    /* Firefox 19+ */
    color: #aba5a5;
    font-size: 14px;
  }
  .header-action-area .btn-search-content form .form-input-item input:-ms-input-placeholder {
    /* IE 10+ */
    color: #aba5a5;
    font-size: 14px;
  }
  .header-action-area .btn-search-content form .form-input-item input:-moz-placeholder {
    /* Firefox 18- */
    color: #aba5a5;
    font-size: 14px;
  }
  .header-action-area .btn-search-content form .form-input-item .btn-src {
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 14px;
    height: 100%;
    line-height: 52px;
    padding-left: 15px;
    padding-right: 14px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .header-action-area .btn-search-content.show {
    bottom: -150px;
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
  @media only screen and (max-width: 991px) {
    .header-action-area .btn-search-content {
      bottom: -140px;
    }
    .header-action-area .btn-search-content.show {
      bottom: -120px;
    }
  }
  
  .header-top {
    background-color: var(--primary);
    padding: 12px 0 14px;
  }
  @media only screen and (max-width: 991px) {
    .header-top {
      display: none;
    }
  }
  .header-top .header-top-nav a {
    color: #bdbfc3;
    font-size: 13px;
    margin-left: 36.43px;
  }
  .header-top .header-top-nav a:first-child {
    margin-left: 0;
  }
  .header-top .header-top-nav a:hover {
    color: var(--white);
  }
  .header-top .header-top-info {
    margin-bottom: 0;
    padding-top: 2px;
  }
  .header-top .header-top-info li {
    color: #bdbfc3;
    font-size: 13px;
    float: left;
    margin-left: 43.66px;
  }
  .header-top .header-top-info li i {
    width: 17px;
  }
  .header-top .header-top-info li:first-child {
    margin-left: 0;
  }
  .header-top .header-icons a {
    color: var(--white);
  }
  .header-top .header-icons a:hover {
    color: var(--primary);
  }
  .header-top.inline-style .header-left {
    float: left;
  }
  .header-top.inline-style .header-right {
    float: right;
  }
  @media only screen and (max-width: 1199px) {
    .header-top.inline-style .container {
      max-width: none;
    }
  }
  .header-top + .header-area2 {
    padding: 25px 0;
  }
  
  .header-area2.fixed-top.sticky-header {
    background-color: transparent;
    box-shadow: none;
    padding: 50px 0;
  }
  
.has-submenu {
  padding-right: 10px;
  position: relative;
}
.has-submenu > a {
  position: relative;
}
.has-submenu > a:before {
  content: "";
  color: #303744;
  font-size: 14px;
  line-height: 30px;
  font-family: "FontAwesome";
  position: absolute;
  right: 11px;
  top: 7px;
}
.has-submenu:hover > .submenu-nav {
  transform: none;
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.has-submenu:hover .menu-content .menu-content-inner h4, .has-submenu:hover .menu-content .menu-content-inner .btn-brand {
  transform: none !important;
}
.has-submenu .submenu-nav {
  background-color: #111;
  border: none;
  border-bottom: none;
  padding: 15px 0 15px;
  position: absolute;
  left: -25px;
  top: 100%;
  transform: translateY(50px);
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  min-width: 300px;
  margin-top: 42px;
  z-index: 9999;
}
@media only screen and (max-width: 1549px) {
  .has-submenu .submenu-nav {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .has-submenu .submenu-nav {
    min-width: 300px;
  }
}
.has-submenu .submenu-nav:before {
  content: "";
  position: absolute;
  height: 56px;
  width: 100%;
  left: 0;
  bottom: 100%;
}
.has-submenu .submenu-nav > li {
  padding: 7px 25px;
}
.has-submenu .submenu-nav > li a {
  color: #aaa;
  display: block;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: inherit;
  text-transform: capitalize;
}
.has-submenu .submenu-nav > li a:hover {
  color: var(--white);
}
.has-submenu .submenu-nav > li:hover > a {
  color: var(--white);
}
.has-submenu .submenu-nav > li:hover:after {
  color: #fff !important;
}
.has-submenu .submenu-nav > li.has-submenu {
  position: relative;
}
.has-submenu .submenu-nav > li.has-submenu a:before {
  display: none;
}
.has-submenu .submenu-nav > li.has-submenu:hover > .submenu-nav {
  transform: none;
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.has-submenu .submenu-nav > li.has-submenu:after {
  content: "";
  color: var(--grey2);
  font-size: 15px;
  line-height: 1.2;
  font-family: "FontAwesome";
  position: absolute;
  right: 25px;
  top: 12px;
}
.has-submenu .submenu-nav > li.has-submenu .submenu-nav {
  left: 100%;
  top: 0;
  margin-top: -15px;
}
.has-submenu .submenu-nav-mega {
  display: flex;
  padding: 40px 50px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .has-submenu .submenu-nav-mega {
    width: 830px;
  }
}
.has-submenu .submenu-nav-mega .mega-menu-item {
  padding: 0 !important;
  flex-basis: 25%;
}
.has-submenu .submenu-nav-mega .mega-menu-item:last-child {
  border-right: 0;
}
.has-submenu .submenu-nav-mega .mega-menu-item > a {
  color: #fff !important;
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.has-submenu .submenu-nav-mega .mega-menu-item > a.srmenu-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin-left: 25px;
  padding: 10px 0;
}
.has-submenu .submenu-nav-mega .mega-menu-item > a.srmenu-title.srmenu-style-hide {
  display: none;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li {
  position: relative;
  padding: 10px 25px;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li a:hover {
  color: var(--white);
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li.feature a {
  position: relative;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li.feature a:before {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  color: var(--primary);
  right: -20px;
  top: 1px;
  font-size: 12px;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li.new a {
  position: relative;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li.new a:after {
  background-color: var(--primary);
  content: "New";
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: -38px;
  line-height: 1;
  padding: 2px 3px;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li:hover .menu-thumb {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.has-submenu .submenu-nav-mega .mega-menu-item ul li.active a {
  color: var(--white);
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content {
  padding: 0 !important;
  margin: -1px;
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner {
  background-size: cover;
  background-position: center center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4, .has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner .btn-brand {
  color: var(--white);
  transition: 0.4s;
  transition-delay: 0.3s;
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4 {
  transform: translateY(-80%);
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4 span {
  color: var(--primary);
  font-style: italic;
}
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner .btn-brand {
  margin-top: 30px;
  font-weight: 700;
  transform: translateY(80%);
}
.has-submenu.full-width {
  position: static;
}
.has-submenu.colunm-two {
  position: relative;
}
.has-submenu.colunm-two .submenu-nav-mega {
  left: -35px;
  width: 600px;
}
.has-submenu.colunm-two .submenu-nav-mega .mega-menu-item {
  padding: 0 !important;
  flex-basis: 50%;
}

.menu-thumb {
  border-radius: 5px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: absolute;
  transform: translateX(30px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s;
  top: -10px;
  right: -80px;
  z-index: 1;
}
@media only screen and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1599.98px) {
  .menu-thumb {
    right: 0;
    top: auto;
    bottom: 100%;
  }
}

/* Responsive Mobile Menu */
.res-mobile-menu {
  margin: 0 -10px;
}
.res-mobile-menu .slicknav_btn {
  display: none;
}
.res-mobile-menu .slicknav_menu {
  background-color: transparent;
  padding: 0;
}
.res-mobile-menu .slicknav_nav {
  display: block !important;
}
.res-mobile-menu .slicknav_nav li {
  border-bottom: none;
  margin-bottom: 1px;
  position: relative;
}
.res-mobile-menu .slicknav_nav li:last-child {
  border-bottom: 0;
}
.res-mobile-menu .slicknav_nav li a {
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  padding: 13px 30px;
  margin: 0;
  text-transform: capitalize;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .res-mobile-menu .slicknav_nav li a {
    font-size: 16px;
    padding: 10px 15px;
  }
}
.res-mobile-menu .slicknav_nav li a .slicknav_arrow {
  background-color: transparent;
  color: #878b93;
  font-size: 17px;
  display: block;
  text-align: center;
  margin: 0;
  position: absolute;
  right: 8px;
  top: 48%;
  transform: translateY(-50%);
  height: 35px;
  line-height: 35px;
}
.res-mobile-menu .slicknav_nav li a a {
  padding: 0;
}
.res-mobile-menu .slicknav_nav li a:hover {
  color: var(--primary);
  background-color: transparent;
}
.res-mobile-menu .slicknav_nav li img {
  display: none;
}
.res-mobile-menu .slicknav_nav li div {
  display: none;
}
.res-mobile-menu .slicknav_nav li ul {
  margin: 0;
  padding-left: 10px;
}
.res-mobile-menu .slicknav_nav li ul li {
  padding-left: 20px;
}
@media only screen and (max-width: 575px) {
  .res-mobile-menu .slicknav_nav li ul li {
    padding-left: 15px;
  }
}
.res-mobile-menu .slicknav_nav li ul li a {
  font-size: 16px;
  color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .res-mobile-menu .slicknav_nav li ul li a {
    font-size: 14px;
  }
}
.res-mobile-menu .slicknav_nav li ul li a:hover {
  color: var(--white);
}
.res-mobile-menu .slicknav_nav li ul li ul li a {
  font-size: 14px;
}
.res-mobile-menu .slicknav_nav .slicknav_open > .slicknav_item {
  position: relative;
}
.res-mobile-menu .slicknav_nav .slicknav_open > .slicknav_item .slicknav_arrow {
  color: var(--primary);
}