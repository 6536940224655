@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800,900,|Libre+Baskerville:400,400i,700);
@import url(bootstrap.min.css);
@import url(icofont.css);

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #ffffff;
  --black: rgb(0, 0, 0);
  --primary: #e02416;
  --secondary:#9b1b12;
  --jeune :#e2ff00;
  --grey1: #999999;
  --grey2: #666666;
  --grey3 : #595959;
  --yellow: #e02416;
  --green: #f1f0f0;
}

body {
  color: var(--grey2);
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.7142857143;
  margin: 0;
  overflow-x: hidden;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: var(--black);
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
  margin-top: 0;
}

a {
  color: var(--primary);
  text-decoration: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
a:hover, a:active, a:focus {
  box-shadow: none;
  color: var(--primary);
  outline: none;
  text-decoration: none;
}
a img {
  border: none;
}

.btn:focus {
  box-shadow: none;
}

p {
  margin-bottom: 30px;
}
p:last-child {
  margin-bottom: 0;
}

:active,
:focus {
  box-shadow: none;
  outline: none !important;
}

::selection {
  background: var(--primary);
  color: var(--white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--primary);
  /* Firefox */
  color: var(--white);
  text-shadow: none;
}

::-webkit-selection {
  background: var(--primary);
  /* Safari */
  color: var(--white);
  text-shadow: none;
}

img {
  max-width: 100%;
}

iframe {
  border: none !important;
}

textarea:focus, textarea:active, input:focus, input:active {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 30px;
}

table p {
  margin-bottom: 0;
}

/*
-----------------------------------------------------------------------
  Common CSS
-----------------------------------------------------------------------
*/

 .containers {
   max-width: 1200px;
   /* padding-left: 15px;
    padding-right: 15px; */
   margin: 15px;
      margin-bottom: -66px;
 }

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .containers {
    max-width: 1200px;
    /* padding-left: 15px;
    padding-right: 15px; */
    margin: 37px;
        margin-bottom: -66px;
  }
}

@media only screen and (min-width: 1200px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 1200px) {
  .row > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 16px;
  }
}

.section-title {
  margin-bottom: 80px;
  /* text-align: left */
}

@media only screen and (max-width: 991px) {
  .section-title {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .section-title {
    margin-bottom: 0px;
  }
}
.section-title h5 {
  color: var(--grey1);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .section-title h5 {
    font-size: 12px;
    margin-bottom: 12px;
    /* padding-top: 32px; */
    /* text-align:'left' */
  }
}
.section-title .title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1.377px;
  line-height: 1.1;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1199px) {
  .section-title .title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .section-title .title {
    font-size: 24px;
  }
}
.section-title .title span {
  color: var(--primary);
  font-weight: 300;
}
.section-title .desc {
  margin-bottom: 55px;
}
.section-title .desc p {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .section-title .desc p br {
    display: none;
  }
}
.section-title .separator-line {
  border-bottom: 1px solid var(--primary);
  display: block;
  margin: 0 auto 40px;
  width: 100px;
}
.section-title .list-style li {
  color: var(--grey2);
  font-size: 14px;
  margin-bottom: 16px;
  padding-left: 29px;
  position: relative;
}
.section-title .list-style li i {
  color: var(--primary);
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 5px;
}
.section-title .list-style li:last-child {
  margin-bottom: 0;
}
.section-title.section-light h5 {
  color: var(--white);
}
.section-title.section-light .title {
  color: var(--white);
}
.section-title.section-light .desc p {
  color: var(--white);
}
.section-title.section-light .separator-line {
  border-bottom-color: #fff !important;
}
.section-title.section-light .separator-line.separator-double:before {
  border-bottom-color: #fff !important;
}

.bg-img {
  background: no-repeat center center;
  background-size: cover;
}

.bg-img.bg-img-top {
  background-position: top center;
}

.site-wrapper-reveal {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.niceselect {
  float: inherit;
}
.niceselect .list {
  border-radius: 0;
  width: 100%;
}

.line-hr {
  border-bottom: 1px solid #e2e2e2;
}

.lh-1 {
  line-height: 1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.content-items-center {
  align-items: center;
  display: flex;
}

.justify-items-center {
  display: flex;
  justify-content: center;
}

.min-height-auto {
  min-height: auto !important;
}

.height-auto {
  height: auto !important;
}

.form-message.alert {
  margin-top: 10px;
}

.row-gutter-0 [class*=col-] {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .md-text-center {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .sm-text-center {
    text-align: center;
  }
}
@media only screen and (max-width: 575px) {
  .xs-w-100 {
    width: 100% !important;
  }
}
/*-------- Button Style Css --------*/

.btn-theme {
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 0;
  color: var(--white);
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  min-height: 50px;
  padding: 10px 30px;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.btn-theme .icon {
  font-size: 14px;
  padding-left: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.btn-theme:hover, .btn-theme:focus {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--white);
}
.btn-theme.btn-black {
  background-color: var(--black);
  border-color: var(--black);
}
.btn-theme.btn-black:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.btn-theme.btn-white {
  background-color: var(--white);
  border-color: var(--white);
  color: #0e0e0e;
}
.btn-theme.btn-white:hover {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.btn-theme.btn-white.btn-border {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: var(--white);
  line-height: 29px;
}
.btn-theme.btn-white.btn-border:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.btn-theme.btn-gray {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #0e0e0e;
}
.btn-theme.btn-gray:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}
.btn-theme.btn-border {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.btn-theme.btn-round {
  border-radius: 25px;
}
.btn-theme.btn-round.btn-slide:before {
  border-radius: 25px;
}
.btn-theme.btn-lg {
  letter-spacing: 0.131px;
  padding: 14px 48px;
}
.btn-theme.btn-slide {
  position: relative;
  z-index: 1;
}
.btn-theme.btn-slide:before {
  background-color: var(--yellow);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.btn-theme.btn-slide:hover:before {
  width: 100%;
}
.btn-theme.btn-size-md {
  height: 50px;
  min-width: 160px;
  text-align: center;
}
.btn-theme.btn-size-lg {
  height: 50px;
  min-width: 180px;
  text-align: center;
}
.btn-theme.btn-size-xl {
  height: 50px;
  min-width: 220px;
  text-align: center;
}
.btn-theme.btn-size-xxl {
  height: 52px;
  min-width: 270px;
  text-align: center;
}

.btn-link {
  color: var(--black);
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.btn-link:hover {
  color: var(--primary);
  text-decoration: none;
}
.btn-link span + .icon {
  color: var(--primary);
  font-size: 14px;
  padding-left: 17px;
}
.btn-link span + .icon:before {
  font-weight: 900;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link.btn-line:after {
  background-color: var(--primary);
  bottom: 0;
  content: "";
  height: 1px;
  right: 0;
  position: absolute;
  width: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.btn-link.btn-line:hover {
  color: var(--black);
}
.btn-link.btn-line:hover:after {
  left: 0;
  width: 100%;
}
.btn-link.btn-dark {
  background-color: transparent;
  border: none;
  color: #0e0e0e;
}
.btn-link.btn-dark:after {
  background-color: #0e0e0e;
  left: 0;
  width: 100%;
}
.btn-link.btn-dark:hover {
  color: var(--primary);
}
.btn-link.btn-dark:hover:hover:before {
  background-color: var(--primary);
}
.btn-link.btn-dark:hover:hover:after {
  background-color: var(--primary);
}

/*-------- Owl Carousel Style Css --------*/
.owl-carousel.owl-theme .owl-nav {
  margin: 0;
}
.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  background-color: transparent;
  font-size: 24px;
  font-weight: 900;
  margin: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.owl-carousel.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--primary);
}
.owl-carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 10px;
}
.owl-carousel.owl-theme .owl-nav .owl-next {
  margin-left: 10px;
}
.owl-carousel.owl-theme.owl-nav2 .owl-nav {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
.owl-carousel.owl-theme.owl-nav2 .owl-nav [class*=owl-] {
  position: absolute;
}
.owl-carousel.owl-theme.owl-nav2 .owl-nav [class*=owl-].owl-prev {
  left: 0;
}
.owl-carousel.owl-theme.owl-nav2 .owl-nav [class*=owl-].owl-next {
  right: 0;
}
.owl-carousel.owl-theme .owl-dots {
  margin-top: 55px;
}
.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: #808080;
  border: 1px solid #808080;
  height: 10px;
  margin: 5px 12px;
  width: 10px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.owl-carousel.owl-theme .owl-dots .owl-dot.active span, .owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--primary);
  border-color: var(--primary);
}
.owl-carousel.owl-dots-none .owl-dots {
  display: none;
}

/*-------- Page Title Style Css --------*/
.page-title-area .page-title-content {
  letter-spacing: 0;
  padding: 5px 0;
}
.page-title-area .page-title-content .title {
  color: var(--black);
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1.377px;
  line-height: 1.1;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content .title {
    font-size: 30px;
  }
}
.page-title-area .page-title-content .title span {
  color: var(--primary);
  font-weight: 300;
}
.page-title-area .page-title-content .bread-crumbs {
  color: #888;
  font-size: 15px;
  margin-bottom: 2px;
}
.page-title-area .page-title-content .bread-crumbs a {
  color: #888;
}
.page-title-area .page-title-content .bread-crumbs a:hover {
  color: var(--primary);
}
.page-title-area .page-title-content .bread-crumbs span.active {
  color: var(--primary);
}
.page-title-area .page-title-content .bread-crumbs .breadcrumb-sep {
  padding: 0 8.7px;
}
.page-title-area .page-title-content .bread-crumbs.bread-crumbs-style2 {
  font-size: 14px;
  margin-bottom: 23px;
  margin-top: 44px;
  /* text-transform: uppercase; */
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content .bread-crumbs.bread-crumbs-style2 {
    margin-top: 22px;
  }
}
.page-title-area .page-title-content.text-light .title {
  color: var(--white);
}
.page-title-area .page-title-content.text-light .bread-crumbs {
  color: var(--white);
}
.page-title-area .page-title-content.text-light .bread-crumbs a {
  color: var(--white);
}
.page-title-area .page-title-content.content-style2 {
  padding: 327px 0 271px;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style2 {
    padding: 266px 0 100px;
  }
}
@media only screen and (max-width: 991px) {
  .page-title-area .page-title-content.content-style2 {
    padding: 235px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style2 {
    padding: 195px 0 65px;
  }
}
.page-title-area .page-title-content.content-style2 .title {
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-size: 90px;
  font-weight: 800;
  letter-spacing: -2.04px;
  line-height: 100px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style2 .title {
    font-size: 64px;
    line-height: 78px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style2 .title {
    font-size: 36px;
    line-height: 1.1;
  }
}
.page-title-area .page-title-content.content-style2 .title span {
  color: var(--white);
  font-weight: 300;
}
.page-title-area .page-title-content.content-style2 p {
  color: var(--white);
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.411px;
  margin-bottom: 34px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style2 p {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .page-title-area .page-title-content.content-style2 p {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style2 p {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.page-title-area .page-title-content.content-style3 {
  padding: 00px 0 0px;
}

@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style3 {
    /* padding: 52px 0 60px; */
    padding: 7px 0 28px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style3 {
    /* padding: 0px 0 0px; */
    padding: 8px 0 0px;
  }
}
.page-title-area .page-title-content.content-style4 {
  padding: 53px 0px 6px;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style4 {
    padding: 8px 0px 6px;
  }
}
.page-title-area .page-title-content.content-style5 {
  padding: 429px 0 269px;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style5 {
    padding: 266px 0 100px;
  }
}
@media only screen and (max-width: 991px) {
  .page-title-area .page-title-content.content-style5 {
    padding: 235px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style5 {
    padding: 195px 0 65px;
  }
}
.page-title-area .page-title-content.content-style5 .title {
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-size: 90px;
  font-weight: 800;
  letter-spacing: -2.04px;
  line-height: 100px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style5 .title {
    font-size: 64px;
    line-height: 78px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style5 .title {
    font-size: 36px;
    line-height: 1.1;
  }
}
.page-title-area .page-title-content.content-style5 .title span {
  color: var(--white);
  font-weight: 300;
}
.page-title-area .page-title-content.content-style5 p {
  color: var(--white);
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.411px;
  margin-bottom: 34px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .page-title-area .page-title-content.content-style5 p {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .page-title-area .page-title-content.content-style5 p {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .page-title-area .page-title-content.content-style5 p {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.bread-crumbs {
  color: var(--grey2);
  font-size: 16px;
}
.bread-crumbs a {
  color: var(--grey2);
}
.bread-crumbs a:hover {
  color: var(--primary);
}

/*-------- Pagination Style Css --------*/
.pagination-area {
  padding: 42px 6px 42px;
}
@media only screen and (max-width: 991px) {
  .pagination-area {
    padding: 50px 0 0;
  }
}
.pagination-area nav .page-numbers {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  display: inline-flex;
  margin-bottom: 0;
}
.pagination-area nav .page-numbers li {
  line-height: 1;
  margin-right: 36.57px;
  padding: 0;
}
.pagination-area nav .page-numbers li .page-number {
  color: var(--grey1);
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
}
.pagination-area nav .page-numbers li .page-number:hover {
  color: var(--black);
}
.pagination-area nav .page-numbers li .page-number.current {
  color: var(--black);
  font-weight: 700;
}
.pagination-area nav .page-numbers li .page-number.prev {
  border-radius: 0;
  color: var(--black);
  font-size: 14px;
}
.pagination-area nav .page-numbers li .page-number.next {
  color: var(--black);
  font-size: 22px;
}
.pagination-area nav .page-numbers li .page-number.next:hover {
  color: var(--black);
}
.pagination-area nav .page-numbers li:first-child {
  padding-left: 0;
}
.pagination-area nav .page-numbers li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.pagination-area nav .page-numbers.page-numbers-style {
  box-shadow: 0px 10px 50px 0px rgba(171, 181, 189, 0.35);
}

/*-------- Blockquote Style Css --------*/
.blockquote-area {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 165px 0 140px;
}
@media only screen and (max-width: 767px) {
  .blockquote-area {
    padding: 122px 0 94px;
  }
}
.blockquote-area p {
  color: var(--white);
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 70px;
}
@media only screen and (max-width: 767px) {
  .blockquote-area p {
    font-size: 24px;
  }
}
.blockquote-area cite .name {
  color: var(--primary);
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.blockquote-area cite .job {
  color: #9192a4;
  font-size: 14px;
}

/*-------- Accordian Style Css --------*/
.accordian-content .accordion .accordion-item {
  border: none;
  margin-bottom: 30px;
  overflow: visible;
  font-size: 17px;
}
.accordian-content .accordion .accordion-item .accordion-header {
  padding: 0;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button {
  align-items: center;
  background-color: var(--white);
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: var(--white);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  padding: 15px 30px;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 1;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button:before {
  background-color: var(--primary);
  border-radius: 5px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button:after {
  display: none;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button .icon {
  color: var(--white);
  font-size: 14px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button .icon.icon-plus {
  display: none;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  box-shadow: 0px 7px 30px 0px rgba(171, 181, 189, 0.35);
  color: var(--black);
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:before {
  background-color: var(--white);
  background-image: none;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed .icon {
  color: var(--black);
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed .icon.icon-plus {
  display: inline-block;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed .icon.icon-minus {
  display: none;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:hover {
  background-color: #f9f9f9;
}
.accordian-content .accordion .accordion-item .accordion-header .accordion-button.collapsed:hover:before {
  background-color: #f9f9f9;
}
.accordian-content .accordion .accordion-item .accordion-body {
  line-height: 30px;
  padding: 34px 30px 16px;
}
.accordian-content .accordion.no-bg .accordion-item {
  margin-bottom: 14px;
}
.accordian-content .accordion.no-bg .accordion-item .accordion-header .accordion-button {
  background-color: transparent;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  color: var(--black);
  padding: 0 0 20px 20px;
}
.accordian-content .accordion.no-bg .accordion-item .accordion-header .accordion-button:before {
  display: none;
}
.accordian-content .accordion.no-bg .accordion-item .accordion-header .accordion-button .icon {
  color: var(--black);
}
.accordian-content .accordion.no-bg .accordion-item .accordion-header .accordion-button.collapsed {
  box-shadow: none;
}
.accordian-content .accordion.no-bg .accordion-item .accordion-header .accordion-button.collapsed:hover {
  background-color: transparent;
}
.accordian-content .accordion.no-bg .accordion-collapse {
  border: none;
}
.accordian-content .accordion.no-bg .accordion-body {
  padding: 20px 35px 14px 20px;
}
.accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button {
  border: none;
  color: var(--primary);
  font-size: 18px;
  font-weight: 700;
  justify-content: normal;
  line-height: 24px;
  padding: 0 30px 13px 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
@media only screen and (max-width: 479.98px) {
  .accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button {
    padding: 0 10px 13px 0;
  }
}
.accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button.collapsed {
  color: var(--black);
  font-weight: 400;
  padding: 0 30px 10px 0;
}
@media only screen and (max-width: 479.98px) {
  .accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button.collapsed {
    padding: 0 10px 10px 0;
  }
}
.accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button.collapsed .icon {
  color: var(--black);
  top: 2px;
}
.accordian-content .accordion.accordion-style2 .accordion-item .accordion-header .accordion-button .icon {
  color: var(--black);
  font-size: 24px;
  left: auto;
  position: absolute;
  right: -35px;
  top: 4px;
}
.accordian-content .accordion.accordion-style2 .accordion-item .accordion-body {
  color: var(--grey1);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0px 30px 9px 16px;
}
@media only screen and (max-width: 479.98px) {
  .accordian-content .accordion.accordion-style2 .accordion-item .accordion-body {
    padding: 0 12px 9px 16px;
  }
}

/*-------- Tab Style Css --------*/
.feature-tab-content .nav.nav-tabs {
  border-bottom: none;
  margin-bottom: 41px;
}
.feature-tab-content .nav.nav-tabs .nav-item {
  margin-right: 60px;
}
@media only screen and (max-width: 767px) {
  .feature-tab-content .nav.nav-tabs .nav-item {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .feature-tab-content .nav.nav-tabs .nav-item {
    margin-right: 15px;
    font-size: 12px;
  }
}
.feature-tab-content .nav.nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: 3px solid #ddd;
  color: var(--black);
  padding: 0 0 13px;
  text-transform: uppercase;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.feature-tab-content .nav.nav-tabs .nav-item .nav-link:hover {
  color: var(--primary);
}
.feature-tab-content .nav.nav-tabs .nav-item .nav-link:focus {
  color: var(--primary);
}
.feature-tab-content .nav.nav-tabs .nav-item .nav-link.active {
  background-color: transparent;
  border-bottom-color: var(--primary);
}
.feature-tab-content .nav.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.feature-tab-content .tab-content .tab-pane p {
  line-height: 30px;
  margin-bottom: 10px;
}

/*-------- Page-Not-Found Style Css --------*/
.page-not-found-area {
  align-items: center;
  display: flex;
  height: 100vh;
  margin-top: 0;
  padding: 0;
}
.page-not-found-area .section-title {
  margin-top: 35px;
  margin-bottom: 45px;
}
@media only screen and (max-width: 1199px) {
  .page-not-found-area .section-title {
    padding-top: 65px;
  }
}
@media only screen and (max-width: 991px) {
  .page-not-found-area .section-title {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .page-not-found-area .section-title {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .page-not-found-area .section-title {
    margin-bottom: 35px;
  }
}
.page-not-found-area .section-title .title {
  color: var(--white);
  font-size: 60px;
  letter-spacing: 0;
  margin-bottom: 18px;
  text-transform: uppercase;
}
@media only screen and (max-width: 991px) {
  .page-not-found-area .section-title .title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 575px) {
  .page-not-found-area .section-title .title {
    font-size: 32px;
  }
}
.page-not-found-area .section-title .desc {
  margin-bottom: 0;
}
.page-not-found-area .section-title .desc p {
  color: var(--white);
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (max-width: 575px) {
  .page-not-found-area .section-title .desc p {
    font-size: 14px;
    line-height: 24px;
  }
}
.page-not-found-area .btn-theme {
  padding: 10px 68px;
}
@media only screen and (max-width: 767px) {
  .page-not-found-area .btn-theme {
    min-height: auto;
    padding: 8px 40px;
  }
}
@media only screen and (max-width: 575px) {
  .page-not-found-area .btn-theme {
    padding: 5px 30px;
    font-size: 13px;
  }
}
.page-not-found-area a {
  text-transform: capitalize;
}
.page-not-found-area a:after {
  width: 100%;
}
.page-not-found-area .widget-search-box.search-style2 .btn-src {
  border-left: none;
}

/*-------- Coming-Soon Style Css --------*/
.coming-soon-area {
  background-position: top right;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 0;
}
.coming-soon-area .countdown-content {
  margin-bottom: 78px;
}
@media only screen and (max-width: 1549px) {
  .coming-soon-area .countdown-content {
    margin-bottom: 35px;
  }
}
.coming-soon-area .countdown-content .countdown-timer li {
  margin: 0 55px;
}
@media only screen and (max-width: 1199px) {
  .coming-soon-area .countdown-content .countdown-timer li {
    margin: 0 25px;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .countdown-content .countdown-timer li {
    margin: 0 15px;
  }
}
.coming-soon-area .countdown-content .countdown-timer li span {
  color: var(--white);
  display: inline-block;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 18px;
}
@media only screen and (max-width: 1549px) {
  .coming-soon-area .countdown-content .countdown-timer li span {
    font-size: 36px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .countdown-content .countdown-timer li span {
    font-size: 16px;
  }
}
.coming-soon-area .countdown-content .countdown-timer li p {
  color: var(--white);
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
}
@media only screen and (max-width: 1549px) {
  .coming-soon-area .countdown-content .countdown-timer li p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .countdown-content .countdown-timer li p {
    font-size: 13px;
  }
}
.coming-soon-area .coming-soon-content {
  margin-top: 83px;
}
@media only screen and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content .section-title.section-style7 .separator-line {
    display: none;
  }
}
.coming-soon-area .coming-soon-content h2 {
  color: var(--white);
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 15px;
  margin-top: 0;
}
@media only screen and (max-width: 1549px) {
  .coming-soon-area .coming-soon-content h2 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon-area .coming-soon-content h2 {
    font-size: 48px;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .coming-soon-content h2 {
    font-size: 24px;
  }
}
.coming-soon-area .coming-soon-content p {
  color: var(--white);
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 1549px) {
  .coming-soon-area .coming-soon-content p {
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon-area .coming-soon-content p {
    font-size: 14px;
  }
  .coming-soon-area .coming-soon-content p br {
    display: none;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .coming-soon-content p {
    font-size: 13px;
  }
}
.coming-soon-area .input-btn-group {
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  height: 34px;
  padding: 1px;
}
.coming-soon-area .input-btn-group .form-control {
  border-radius: 50px;
  font-size: 14px;
  max-width: 85.3%;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .coming-soon-area .input-btn-group .form-control {
    max-width: 420px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon-area .input-btn-group .form-control {
    max-width: 320px;
  }
}
@media only screen and (max-width: 479.98px) {
  .coming-soon-area .input-btn-group .form-control {
    max-width: 170px;
  }
}
.coming-soon-area .input-btn-group .btn-theme {
  background-color: var(--white);
  border-color: var(--white);
  border-radius: 0 25px 25px 0;
  color: #c3c3c3;
  height: 32px;
  min-height: 32px;
  line-height: 31px;
  padding: 0 18px;
}
.coming-soon-area .input-btn-group .btn-theme:hover {
  background-color: var(--black);
  border-color: var(--black);
}

/*-------- Preloader Style Css --------*/
.preloader-deactive .preloader-wrap {
  display: none;
}

.preloader-wrap {
  background-color: var(--white);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  overflow: hidden;
}
.preloader-wrap .preloader {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -50px;
}
.preloader-wrap .preloader .dot {
  height: 100%;
  width: 100%;
  display: block;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  animation: la-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: var(--primary);
}
@keyframes la-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/*-------- Custom Font Style Css --------*/
.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-44 {
  font-size: 44px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

@media only screen and (max-width: 991px) {
  .font-size-sm-18 {
    font-size: 18px !important;
  }

  .font-size-sm-24 {
    font-size: 24px !important;
  }
}
.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.line-height-30 {
  line-height: 30px !important;
}

.letter-spacing-0 {
  letter-spacing: 0 !important;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

/*-------- Custom Color Style Css --------*/
.bgcolor-black {
  background-color: #000 !important;
}

.bgcolor-black-light {
  background-color: #1d1f1f !important;
}

.bgcolor-white {
  background-color: #fff !important;
}

.bgcolor-24 {
  background-color: #242424 !important;
}

.bgcolor-333 {
  background-color: #333333 !important;
}

.bgcolor-f5 {
  background-color: #f5f5f5 !important;
}

.bgcolor-f6 {
  background-color: #f6f6f6 !important;
}

.bgcolor-gray-light {
  background-color: #f4f6f8 !important;
}

.bgcolor-gray-lighter {
  background-color: #eee !important;
}

.bgcolor-gray {
  background-color: #f0f2f4 !important;
}

.bgcolor-gray-silver {
  background-color: #f0f1f3 !important;
}

.bgcolor-gray-ccc {
  background-color: #ccc !important;
}

.bgcolor-theme {
  background-color: #339999 !important;
}

.bgcolor-theme2 {
  background-color: #303744 !important;
}

.bgcolor-theme3 {
  background-color: var(--yellow) !important;
}

.bgcolor-theme4 {
  background-color: #f53400 !important;
}

.text-theme-color {
  color: #339999 !important;
}

.text-theme-color2 {
  color: #303744 !important;
}

.text-theme-color3 {
  color: var(--yellow) !important;
}

.text-theme-color4 {
  color: #f53400 !important;
}

.text-theme-color5 {
  color: #568701 !important;
}

.text-light {
  color: #fff !important;
}

.text-dark {
  color: #0e0e0e !important;
}

.text-black {
  color: #000 !important;
}

.text-ccc {
  color: #ccc !important;
}

.text-hover-theme-color:hover {
  color: #339999 !important;
}

/*-------- Custom Margin Padding Style Css --------*/
.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

@media only screen and (max-width: 1199px) {
  .mt-lg-50 {
    margin-top: 50px !important;
  }

  .mt-lg-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (max-width: 991px) {
  .md-mt-0 {
    margin-top: 0 !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-50 {
    margin-top: 50px !important;
  }

  .mt-md-60 {
    margin-top: 60px !important;
  }

  .mt-md-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .sm-mt-0 {
    margin-top: 0 !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 575px) {
  .mt-xs-0 {
    margin-top: 0px;
  }

  .mt-xs-30 {
    margin-top: 30px !important;
  }
}
.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-80 {
  margin-right: 80px;
}

.mr--100 {
  margin-right: -100px;
}

@media only screen and (max-width: 991px) {
  .md-mr-0 {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .mr-xs-0 {
    margin-right: 0;
  }

  .mr-xs-15 {
    margin-right: 15;
  }
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-438 {
  margin-bottom: 438px !important;
}

.mb-470 {
  margin-bottom: 470px !important;
}

.mb-471 {
  margin-bottom: 471px !important;
}

@media only screen and (max-width: 1199px) {
  .lg-mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-30 {
    margin-bottom: 30px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg-50 {
    margin-bottom: 50px !important;
  }

  .mb-lg-70 {
    margin-bottom: 70px !important;
  }

  .mb-lg-80 {
    margin-bottom: 80px !important;
  }

  .mb-lg-448 {
    margin-bottom: 448px !important;
  }
}
@media only screen and (max-width: 991px) {
  .md-mb-0, .md-mb-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-30 {
    margin-bottom: 30px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-50 {
    margin-bottom: 50px !important;
  }

  .mb-md-60 {
    margin-bottom: 60px !important;
  }

  .mb-md-70 {
    margin-bottom: 70px !important;
  }

  .mb-md-80 {
    margin-bottom: 80px !important;
  }

  .mb-md-90 {
    margin-bottom: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-sm-0, .sm-mb-0 {
    margin-bottom: 0px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-30 {
    margin-bottom: 30px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm-50 {
    margin-bottom: 50px !important;
  }

  .mb-sm-60 {
    margin-bottom: 60px !important;
  }

  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (max-width: 575px) {
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }

  .mb-xs-30 {
    margin-bottom: 30px !important;
  }

  .mb-xs-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (max-width: 479.98px) {
  .mb-xxs-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxs-30 {
    margin-bottom: 30px !important;
  }
}
.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px !important;
}

@media only screen and (max-width: 991px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }

  .md-ml-0 {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 479.98px) {
  .ml-xxs-5 {
    margin-left: 5px !important;
  }
}
.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-170 {
  padding-bottom: 170px;
}

@media only screen and (max-width: 1199px) {
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }

  .pb-lg-30 {
    padding-bottom: 30px !important;
  }

  .pb-lg-70 {
    padding-bottom: 70px !important;
  }

  .pb-lg-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (max-width: 991px) {
  .md-pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-10 {
    padding-bottom: 10px !important;
  }

  .pb-md-20 {
    padding-bottom: 20px !important;
  }

  .pb-md-30 {
    padding-bottom: 30px !important;
  }

  .pb-md-40 {
    padding-bottom: 40px !important;
  }

  .pb-md-50 {
    padding-bottom: 50px !important;
  }

  .pb-md-60 {
    padding-bottom: 60px !important;
  }

  .pb-md-70 {
    padding-bottom: 70px !important;
  }

  .pb-md-80 {
    padding-bottom: 80px !important;
  }

  .pb-md-90 {
    padding-bottom: 90px !important;
  }

  .pb-md-100 {
    padding-bottom: 100px !important;
  }

  .pb-md-120 {
    padding-bottom: 120px !important;
  }

  .pb-md-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .sm-pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-20 {
    padding-bottom: 20px !important;
  }

  .pb-sm-30 {
    padding-bottom: 30px !important;
  }

  .pb-sm-50 {
    padding-bottom: 50px !important;
  }

  .pb-sm-60 {
    padding-bottom: 60px !important;
  }

  .pb-sm-70 {
    padding-bottom: 70px !important;
  }

  .pb-sm-80 {
    padding-bottom: 80px !important;
  }

  .pb-sm-100 {
    padding-bottom: 100px !important;
  }

  .pb-sm-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (max-width: 575px) {
  .pb-xs-80 {
    padding-bottom: 80px !important;
  }
}
.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-130 {
  padding-left: 130px !important;
}

@media only screen and (max-width: 1199px) {
  .pl-lg-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 991px) {
  .md-pl-0 {
    padding-left: 0 !important;
  }

  .md-pl-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .sm-pl-0 {
    padding-left: 0 !important;
  }

  .sm-pl-15, .pl-sm-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 479.98px) {
  .pl-xxs-0 {
    padding-left: 0 !important;
  }
}
.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

@media only screen and (max-width: 1199px) {
  .lg-pt-0 {
    padding-top: 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .pt-md-10 {
    padding-top: 10px !important;
  }

  .pt-md-30 {
    padding-top: 30px !important;
  }

  .pt-md-40 {
    padding-top: 40px !important;
  }

  .pt-md-50 {
    padding-top: 50px !important;
  }

  .pt-md-60 {
    padding-top: 60px !important;
  }

  .pt-md-65 {
    padding-top: 65px !important;
  }

  .pt-md-70 {
    padding-top: 70px !important;
  }

  .pt-md-80 {
    padding-top: 80px !important;
  }

  .pt-md-90 {
    padding-top: 90px !important;
  }

  .pt-md-100 {
    padding-top: 100px !important;
  }

  .pt-md-120 {
    padding-top: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-sm-10 {
    padding-top: 10px !important;
  }

  .pt-sm-30 {
    padding-top: 30px !important;
  }

  .pt-sm-50 {
    padding-top: 50px !important;
  }

  .pt-sm-60 {
    padding-top: 60px !important;
  }

  .pt-sm-70 {
    padding-top: 70px !important;
  }

  .pt-sm-80 {
    padding-top: 80px !important;
  }

  .pt-sm-90 {
    padding-top: 90px !important;
  }

  .pt-sm-100 {
    padding-top: 100px !important;
  }
}
@media only screen and (max-width: 575px) {
  .pt-xs-40 {
    padding-top: 40px !important;
  }
}
.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

@media only screen and (max-width: 1199px) {
  .lg-pr-0 {
    padding-right: 0 !important;
  }

  .pr-lg-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .sm-pr-0 {
    padding-right: 0 !important;
  }

  .sm-pr-15, .pr-sm-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 991px) {
  .md-pr-15 {
    padding-right: 15px;
  }

  .md-pl-15 {
    padding-left: 15px;
  }
}
.d-contents {
  display: contents;
}

/*
-----------------------------------------------------------------------
	Service CSS
-----------------------------------------------------------------------
*/
.service-area {
  padding: 149px 0 165px;
}
@media only screen and (max-width: 1199px) {
  .service-area {
    padding: 98px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .service-area {
    padding: 78px 0 80px;
  }
}
.service-area .section-title {
  margin-bottom: 89px;
}
@media only screen and (max-width: 1199px) {
  .service-area .section-title {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .service-area .section-title {
    margin-bottom: 50px;
  }
}

.service-item .icon {
  color: var(--primary);
  font-size: 46px;
  line-height: 1;
  margin-bottom: 35px;
}
.service-item .content {
  position: relative;
}
.service-item .content .service-name {
  color: #010101;
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 18px;
}
.service-item .content p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 30px;
}
.service-item .content .btn-link {
  color: var(--black);
  font-size: 14px;
  letter-spacing: 0.25px;
}
.service-item .content .btn-link i {
  color: #444;
  font-size: 17px;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.service-style2 {
  border: 1px solid #e3e3e3;
  margin: 0;
}
.service-style2 .service-item {
  border-bottom: 1px solid var(--white);
  border-right: 1px solid #ebebeb;
  padding: 49px 30px 37px 41px;
  position: relative;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
@media only screen and (max-width: 1199px) {
  .service-style2 .service-item {
    padding: 40px 20px;
  }
}
.service-style2 .service-item:before {
  background-color: var(--primary);
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -ms-transition: 0.1s;
  -o-transition: 0.1s;
}
.service-style2 .service-item .icon {
  align-items: flex-end;
  display: flex;
  min-height: 48px;
  padding-left: 1px;
}
.service-style2 .service-item:nth-child(1), .service-style2 .service-item:nth-child(2), .service-style2 .service-item:nth-child(3), .service-style2 .service-item:nth-child(4) {
  border-bottom-color: #ebebeb;
}
.service-style2 .service-item:nth-child(4), .service-style2 .service-item:nth-child(8) {
  border-right-color: var(--white);
}
@media only screen and (max-width: 991px) {
  .service-style2 .service-item:nth-child(5), .service-style2 .service-item:nth-child(6) {
    border-bottom: 1px solid #ebebeb;
  }
}
.service-style2 .service-item:hover {
  background-color: #f2f6f6;
}
.service-style2 .service-item:hover:before {
  height: 4px;
}
.service-style2 .service-item:hover .content .btn-link {
  color: var(--primary);
}

.service-category .category-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 26px;
  position: relative;
}
.service-category .category-item h4 {
  color: var(--white);
  font-size: 24px;
  margin-bottom: 1px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .service-category .category-item h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
  }
}
.service-category .category-item p {
  color: var(--white);
}
.service-category .category-item .icon {
  color: var(--white);
  font-size: 48px;
  position: absolute;
  right: 0;
  top: -21px;
}
.service-slider-container {
  margin-top: 161px;
}
@media only screen and (max-width: 1199px) {
  .service-slider-container {
    margin-top: 131px;
  }
}
@media only screen and (max-width: 991px) {
  .service-slider-container {
    margin-top: 106px;
  }
}
@media only screen and (max-width: 767px) {
  .service-slider-container {
    margin-top: 86px;
  }
}
@media only screen and (max-width: 575px) {
  .service-slider-container {
    margin: 66px auto 0;
    max-width: 300px;
  }
}
@media (max-width: 414px) {
  .service-slider-container {
    margin-top: 70%; 
    /* ///================================ */
  }
}

@media (max-width: 375px) {
  .service-slider-container {
    margin-top: 65%;
    /* ///================================ */
  }
}

@media (max-width: 320px) {
  .service-slider-container {
    margin-top: 45%;
    /* ///================================ */
  }
}

.service-slider-container .service-slider.service-category .category-item {
  padding-top: 4px;
}
.service-slider-container .service-slider.service-category .category-item .icon {
  top: -20px;
  /* top: -23px;
    right: -8px; */
}


/*
-----------------------------------------------------------------------
	Gallery CSS
-----------------------------------------------------------------------
*/

.gallery-item .thumb {
  position: relative;
}
.gallery-item .thumb img {
  width: 100%;
}
.gallery-item .thumb .overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.gallery-item .thumb .overlay i {
  color: var(--white);
  font-size: 36px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.gallery-item:hover .thumb .overlay {
  opacity: 1;
  visibility: visible;
}


/*
-----------------------------------------------------------------------
  Brand Logo CSS
-----------------------------------------------------------------------
*/
.brand-logo-area.brand-logo-default-area {
  border-top: 1px solid #dfdfdf;
  margin-top: 77px;
  padding-top: 100px;
}
@media only screen and (max-width: 1199px) {
  .brand-logo-area.brand-logo-default-area {
    margin-top: 40px;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .brand-logo-area.brand-logo-default-area {
    margin-top: 25px;
    padding-top: 65px;
  }
}
@media only screen and (max-width: 575px) {
  .brand-logo-area.brand-logo-default-area {
    margin-top: 15px;
    padding-top: 55px;
  }
}

.brand-logo-item img {
  opacity: 0.5;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.brand-logo-item:hover img {
  opacity: 1;
}

/*
-----------------------------------------------------------------------
	Divider CSS
-----------------------------------------------------------------------
*/
.divider-area .divider-content-area .content-inner {
  position: relative;
}
.divider-area .divider-content-area h2 {
  margin-bottom: 10px;
}
.divider-area .divider-content-area p {
  margin-bottom: 0;
}
.divider-area .divider-content-area.divider-content-style1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 95px 0 104px;
}
@media only screen and (max-width: 767px) {
  .divider-area .divider-content-area.divider-content-style1 {
    display: block;
    padding: 75px 0 84px;
  }
}
.divider-area .divider-content-area.divider-content-style1 .content-inner h2 {
  color: var(--white);
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 1.1;
  margin-bottom: 22px;
}
@media only screen and (max-width: 991px) {
  .divider-area .divider-content-area.divider-content-style1 .content-inner h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .divider-area .divider-content-area.divider-content-style1 .content-inner h2 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .divider-area .divider-content-area.divider-content-style1 .content-inner h2 {
    font-size: 30px;
  }
}
.divider-area .divider-content-area.divider-content-style1 .content-inner h2 span {
  color: var(--white);
  font-weight: 300;
}
.divider-area .divider-content-area.divider-content-style1 .content-inner p {
  color: var(--white);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.15px;
}
@media only screen and (max-width: 767px) {
  .divider-area .divider-content-area.divider-content-style1 .content-inner p {
    margin-bottom: 20px;
  }
}
.divider-area .divider-content-area.divider-content-style1 .btn-theme {
  margin-top: 9px;
}
.divider-area .divider-content-area.divider-content-style1 .btn-theme.btn-white {
  color: var(--primary);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.097px;
  padding: 19px 47px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .divider-area .divider-content-area.divider-content-style1 .btn-theme.btn-white {
    font-size: 14px;
    padding: 11px 30px;
  }
}
.divider-area .divider-content-area.divider-content-style1 .btn-theme.btn-white:hover {
  color: var(--white);
}

.shape-layer .layer {
  position: absolute;
}
.shape-layer .layer-one {
  bottom: 0;
  left: 231px;
}
.shape-layer .layer-two {
  bottom: 0;
  right: 182px;
}
.shape-layer .layer-three {
  bottom: 0;
  left: 3.4%;
}
.shape-layer .layer-four {
  top: 39%;
  left: 6.8%;
}
@media only screen and (max-width: 991px) {
  .shape-layer .layer-four {
    top: 36.5%;
  }
}
.shape-layer .layer-five {
  top: 39%;
  right: 19.2%;
}
@media only screen and (max-width: 1199px) {
  .shape-layer .layer-five {
    right: 4.2%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-layer .layer-five {
    top: 36.5%;
  }
}
.shape-layer.shape-layer-slyle1 {
  background-image: url("../../assets//img//photos/department-s1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 38%;
}

/*
-----------------------------------------------------------------------
  Icon Box CSS
-----------------------------------------------------------------------
*/
.icon-box-style1 .icon-box-item {
  display: flex;
  margin-bottom: 96px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .icon-box-style1 .icon-box-item {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-box-style1 .icon-box-item {
    margin-bottom: 50px;
  }
}
.icon-box-style1 .icon-box-item .icon {
  color: var(--primary);
  font-size: 72px;
  float: left;
  line-height: 1;
  margin-right: 26px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.icon-box-style1 .icon-box-item .content {
  font-size: 14px;
}
.icon-box-style1 .icon-box-item .content .title {
  font-size: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .icon-box-style1 .icon-box-item .content .title {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.icon-box-style1 .icon-box-item .content .title a {
  color: #0e0e0e;
}
.icon-box-style1 .icon-box-item .content .title a:hover {
  color: var(--primary);
}
.icon-box-style1 .icon-box-item .content p {
  font-size: 13px;
  line-height: 24px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.icon-box-style1 .icon-box-item .content .btn-theme {
  margin-top: 10px;
}
.icon-box-style1 .icon-box-item:hover .content p {
  color: var(--black);
}

.icon-box-style2 .icon-box-item {
  position: relative;
  z-index: 0;
}
.icon-box-style2 .icon-box-item:before {
  background-color: var(--white);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

/*
-----------------------------------------------------------------------
	Form CSS
-----------------------------------------------------------------------
*/
.input-btn-group {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 35px 100px 0px rgba(171, 181, 189, 0.35);
  display: flex;
  padding: 5px 5px 5px 0;
}
.input-btn-group .form-control {
  border: none;
  border-radius: 0;
  border-right: 2px solid #eaeaea;
  height: 24px;
  padding-left: 30px;
}
.input-btn-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aba5a5;
}
.input-btn-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #aba5a5;
}
.input-btn-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #aba5a5;
}
.input-btn-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #aba5a5;
}
.input-btn-group .form-control.no-border {
  border: none;
}
.input-btn-group .form-control:focus {
  box-shadow: none;
}
.input-btn-group .btn-theme {
  border-radius: 5px;
  height: 50px;
}

.appointment-form .form-group {
  margin-bottom: 52px;
}
.appointment-form .form-group .form-control {
  background-color: transparent;
  background-clip: inherit;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  color: var(--black);
  font-size: 14px;
  height: auto;
  padding: 0 1px 10px;
}
.appointment-form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--grey1);
}
.appointment-form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--grey1);
}
.appointment-form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey1);
}
.appointment-form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--grey1);
}
.appointment-form .form-group .form-control:focus {
  border-bottom: 1px solid var(--black);
  box-shadow: none;
  outline: none;
}
.appointment-form .form-group textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  color: var(--black);
  font-size: 14px;
  height: 162px;
  padding: 0 1px;
  width: 100%;
}
.appointment-form .form-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--grey1);
}
.appointment-form .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--grey1);
}
.appointment-form .form-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey1);
}
.appointment-form .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--grey1);
}
.appointment-form .form-group textarea:focus {
  border-bottom: 1px solid var(--black);
  box-shadow: none;
  outline: none;
}
.appointment-form .form-group .btn-theme {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.428px;
  margin-top: 40px;
  min-height: 62px;
  padding: 0 47.43px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .appointment-form .form-group .btn-theme {
    font-size: 16px;
    min-height: 50px;
    padding: 0 20px;
  }
}
.appointment-form .form-group .btn-theme:hover, .appointment-form .form-group .btn-theme:focus {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.appointment-form .form-group.datepicker-group {
  position: relative;
}
.appointment-form .form-group.datepicker-group label {
  bottom: -1px;
  color: var(--grey1);
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 0;
}

.appointment-form-style1 {
  background-color: var(--white);
  box-shadow: 0px 50px 120px 0px rgba(68, 89, 89, 0.15);
  margin: 0 auto -47px;
  max-width: 1310px;
  text-align: center;
  transform: translateY(-148px);
}

@media only screen and (max-width: 812px) {
  .appointment-form-style1 {
      background-color: var(--white);
      box-shadow: 0px 50px 120px 0px rgba(255, 255, 255, 0.15);
      margin: 0 auto -47px;
      max-width: 1310px;
      text-align: center;
      transform: translateY(-148px);
    }
}

@media only screen and (max-width: 1399px) {
  .appointment-form-style1 {
    max-width: 1170px;
  }
}
@media only screen and (max-width: 1199px) {
  .appointment-form-style1 {
    margin-bottom: 0;
    max-width: none;
    transform: none;
  }
}
.appointment-form-style1 .appointment-form {
  padding: 80px 0 100px;
}
@media only screen and (max-width: 1399px) {
  .appointment-form-style1 .appointment-form {
    padding: 80px 30px 100px;
  }
}
@media only screen and (max-width: 1199px) {
  .appointment-form-style1 .appointment-form {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 767px) {
  .appointment-form-style1 .appointment-form {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-form-style1 .appointment-form {
    padding: 70px 0; /* /===================70====================/ */
  }
}

.contact-form .form-group {
  margin-bottom: 30px;
}
.contact-form .form-group .form-control {
  background-clip: inherit;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  font-size: 14px;
  height: 50px;
  padding: 12px 30px;
}
.contact-form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--grey1);
}
.contact-form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--grey1);
}
.contact-form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey1);
}
.contact-form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--grey1);
}
.contact-form .form-group .form-control:focus {
  border: 1px solid var(--primary);
  box-shadow: none;
  outline: none;
}
.contact-form .form-group textarea {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  font-size: 14px;
  min-height: 170px;
  padding: 20px 30px 10px;
  width: 100%;
}
.contact-form .form-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--grey1);
}
.contact-form .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--grey1);
}
.contact-form .form-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey1);
}
.contact-form .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--grey1);
}
.contact-form .form-group textarea:focus {
  border: 1px solid var(--primary);
  box-shadow: none;
  outline: none;
}
.contact-form .form-group .btn-theme {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 51px;
  padding: 10px 26.05px;
}
.contact-form .form-group .btn-theme:hover, .contact-form .form-group .btn-theme:focus {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}

.comment-form-wrap form .comment-form-content .form-group {
  margin-bottom: 30px;
}
.comment-form-wrap form .comment-form-content .form-group .form-control {
  border: none;
  border-radius: 0;
  font-size: 14px;
  height: 49px;
  padding: 7px 21px;
}
.comment-form-wrap form .comment-form-content .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--grey1);
}
.comment-form-wrap form .comment-form-content .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--grey1);
}
.comment-form-wrap form .comment-form-content .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey1);
}
.comment-form-wrap form .comment-form-content .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--grey1);
}
.comment-form-wrap form .comment-form-content .form-group .form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.comment-form-wrap form .comment-form-content .form-group textarea.form-control {
  min-height: 217px;
}
.comment-form-wrap form .comment-form-content .form-group .btn-theme {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.25px;
  margin-top: 17px;
  min-height: 60px;
  padding: 10px 28.96px;
}

.search-box-wrapper {
  background-color: rgba(51, 153, 153, 0.9);
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
}
.search-box-wrapper .search-box-content-inner {
  width: 70%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  transition: transform 0.4s;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 575px) {
  .search-box-wrapper .search-box-content-inner {
    width: 100%;
    padding: 0 20px;
  }
}
.search-box-wrapper.show .search-box-content-inner {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.search-box-wrapper .search-close {
  color: var(--white);
  font-size: 70px;
  line-height: 1;
  position: absolute;
  top: 50px;
  right: 100px;
}
@media only screen and (max-width: 767px) {
  .search-box-wrapper .search-close {
    top: 20px;
    right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .search-box-wrapper .search-close {
    top: 30px;
    right: 30px;
  }
}
.search-box-wrapper .search-close:hover {
  color: var(--primary);
  transform: rotate(90deg);
}

.search-box-form-wrap {
  width: 100%;
}
.search-box-form-wrap input[type=search] {
  background-color: transparent;
  border: none;
  border-bottom: 5px solid var(--primary);
  color: var(--primary);
  display: block;
  font-size: 120px;
  line-height: 1;
  padding: 20px 0;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .search-box-form-wrap input[type=search] {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .search-box-form-wrap input[type=search] {
    font-size: 40px;
  }
}
.search-box-form-wrap .search-note {
  color: var(--primary);
  font-weight: 700;
  text-align: right;
  margin-top: 20px;
}

.subscribe-form .subscribe-form-email {
  border: 1px solid #ddd;
  border-radius: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  position: relative;
}
.subscribe-form .subscribe-form-email:before {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  line-height: 60px;
}
.subscribe-form .subscribe-form-email input {
  background-color: transparent;
  border: none;
  border-radius: 0 30px 30px 0;
  color: var(--primary);
  height: 60px;
  line-height: 60px;
  padding-left: 35px;
  width: 100%;
}
.subscribe-form .subscribe-form-email input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--primary);
}
.subscribe-form .subscribe-form-email input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--primary);
}
.subscribe-form .subscribe-form-email input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--primary);
}
.subscribe-form .subscribe-form-email input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--primary);
}
.subscribe-form .subscribe-form-submit input {
  background-color: var(--primary);
  border: none;
  border-radius: 30px;
  color: var(--white);
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  text-transform: uppercase;
  width: 100%;
}
.subscribe-form p {
  color: var(--primary);
  font-size: 14px;
  line-height: 30px;
}
.subscribe-form.inline-style {
  display: flex;
  justify-content: center;
}
.subscribe-form.inline-style .subscribe-form-email {
  border-color: var(--black);
  border-right: 0;
  border-radius: 0;
  height: 60px;
  margin-bottom: 0;
  padding-left: 50px;
  width: 478px;
}
.subscribe-form.inline-style .subscribe-form-submit input {
  background-color: transparent;
  border: 1px solid var(--black);
  border-radius: 0;
  color: var(--black);
  margin-bottom: 0;
  width: 160px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.subscribe-form.inline-style .subscribe-form-submit input:hover {
  background-color: var(--black);
  color: var(--white);
}

.registration-form form .form-group {
  margin-bottom: 40px;
}
.registration-form form .form-group .form-control {
  background-color: #134f8a;
  border: none;
  border-radius: 2px;
  color: var(--white);
  font-size: 16px;
  min-height: 60px;
  padding: 15px 40px;
}
.registration-form form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #487eb4;
  font-size: 16px;
}
.registration-form form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #487eb4;
  font-size: 16px;
}
.registration-form form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #487eb4;
  font-size: 16px;
}
.registration-form form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #487eb4;
  font-size: 16px;
}
.registration-form form .form-group .form-control:active, .registration-form form .form-group .form-control:focus {
  color: var(--white);
}
.registration-form form .form-group .form-control:active::-webkit-input-placeholder, .registration-form form .form-group .form-control:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--white);
}
.registration-form form .form-group .form-control:active::-moz-placeholder, .registration-form form .form-group .form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--white);
}
.registration-form form .form-group .form-control:active:-ms-input-placeholder, .registration-form form .form-group .form-control:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--white);
}
.registration-form form .form-group .form-control:active:-moz-placeholder, .registration-form form .form-group .form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  color: var(--white);
}
.registration-form form .form-group .btn-theme {
  border-radius: 2px;
  font-size: 16px;
  letter-spacing: 0;
  min-height: 60px;
  text-transform: capitalize;
}
.registration-form form p {
  color: #8bbff4;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 33px;
}
.registration-form form p a {
  color: var(--white);
}

.form-message {
  overflow: hidden;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.form-message button.btn-close {
  border: none;
  box-shadow: none;
  background-color: transparent;
  float: right;
  font-size: 14px;
  margin-top: 0;
  text-indent: 1000px;
}


.team-single {
  position: relative;
}
.team-single .title {
  font-size: 36px;
  letter-spacing: -1.04px;
  margin-bottom: 59px;
}
@media only screen and (max-width: 767px) {
  .team-single .title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.team-single .membr-info {
  letter-spacing: 0;
  max-width: 310px;
  width: 100%;
}
@media (max-width: 702px) {
  .team-single .membr-info {
    max-width: none;
  }
}
.team-single .membr-info .name {
  color: #2b2b2b;
  font-size: 22px;
  letter-spacing: 0.25px;
  margin-bottom: 13px;
}
.team-single .membr-info .designation {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
}
.team-single .membr-info .desc p {
  color: #888;
  margin-bottom: 28px;
}
.team-single .membr-info .desc ul {
  margin-bottom: 52px;
}
.team-single .membr-info .desc ul li {
  color: #888;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 22px;
  position: relative;
}
.team-single .membr-info .desc ul li:before {
  border-bottom: 1px dashed #888;
  bottom: 3px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.team-single .membr-info .desc ul li:last-child {
  margin-bottom: 0;
}
.team-single .membr-info .desc ul li span {
  background-color: var(--white);
  position: relative;
  z-index: 1;
}
.team-single .membr-info .desc ul li span:last-child {
  color: #2b2b2b;
  font-weight: 600;
}
.team-single .membr-info .desc .btn-link {
  background-color: transparent;
  border: none;
  color: var(--primary);
  font-weight: 700;
  letter-spacing: 1.1371px;
  padding: 0;
  text-transform: uppercase;
}
.team-single .membr-info .desc .btn-link:hover {
  color: #568701;
}
.team-single .thumb {
  bottom: 0;
  position: absolute;
  right: 23px;
}
@media (max-width: 702px) {
  .team-single .thumb {
    display: none;
  }
}
.team-single .thumb img {
  max-width: none;
}

/*
-----------------------------------------------------------------------
	Cases CSS
-----------------------------------------------------------------------
*/
.case-item .thumb {
  margin-bottom: 30px;
}
.case-item .thumb img {
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.case-item .content {
  position: relative;
}
.case-item .content .name {
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.case-item .content .name a {
  color: var(--black);
}
.case-item .content .category {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.case-item:hover .thumb img {
  border-radius: 3px;
}
.case-item:hover .content .name a {
  color: #568701;
}

.case-slider-container .swiper-pagination {
  align-items: center;
  display: flex;
  margin: 27px auto 0;
  min-height: 15px;
  max-width: 64px;
  position: relative;
}
.case-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #e6e6e6;
  border-radius: 50%;
  height: 10px;
  margin: 0 5px;
  opacity: 1;
  width: 10px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.case-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:hover {
  background-color: var(--primary);
}
.case-slider-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary);
  height: 14px;
  width: 14px;
}

/*
-----------------------------------------------------------------------
	Home Default CSS
-----------------------------------------------------------------------
*/
.home-default-wrapper .appointment-area {
  padding: 0 0 63px;
}
@media only screen and (max-width: 1199px) {
  .home-default-wrapper .appointment-area {
    padding: 0;
  }
}
.home-default-wrapper .accordian-content {
  padding-right: 30px;
  padding-top: 2px;
}
.home-default-wrapper .accordian-content .accordion {
  margin-bottom: 52px;
}
@media only screen and (max-width: 991px) {
  .home-default-wrapper .accordian-content .accordion {
    margin-bottom: 30px;
  }
}
.home-default-wrapper .accordian-content .btn-line {
  border-bottom: 1px solid #7e7e7e;
  color: var(--grey2);
  display: inline-block;
  line-height: 1;
  padding-bottom: 1px;
  text-transform: uppercase;
}
.home-default-wrapper .accordian-content .btn-line:hover {
  border-bottom-color: var(--primary);
  color: var(--primary);
}
.home-default-wrapper .map-area {
  background-color: #ddd;
  height: 667px;
}
@media only screen and (max-width: 1399px) {
  .home-default-wrapper .map-area {
    height: 360px;
  }
}
@media only screen and (max-width: 767px) {
  .home-default-wrapper .map-area {
    height: 280px;
  }
}
.home-default-wrapper .map-area iframe {
  height: 100%;
  width: 100%;
}
/*
-----------------------------------------------------------------------
	Contact CSS
-----------------------------------------------------------------------
*/
.contact-area {
  background-color: #dddddd;
  padding: 0 0 181px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .contact-area {
    padding: 0 0 100px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-area {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 767px) {
  .contact-area {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 767px) {
  .contact-area .container {
    max-width: none;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 479.98px) {
  .contact-area .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.contact-info-content {
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  margin-bottom: 111px;
  margin-top: -87px;
  padding: 77px 47px 92px 71px;
}
@media only screen and (max-width: 1199px) {
  .contact-info-content {
    margin-bottom: 98px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-info-content {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .contact-info-content {
    display: block;
    margin-bottom: 80px;
    padding: 57px 27px 72px 31px;
  }
}
@media only screen and (max-width: 479.98px) {
  .contact-info-content {
    display: block;
    margin-bottom: 70px;
    padding: 57px 17px 62px 21px;
  }
}
.contact-info-content .info-address {
  max-width: 375px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .contact-info-content .info-address {
    margin-bottom: 50px;
    max-width: none;
    padding-bottom: 40px;
  }
}
.contact-info-content .info-address:after {
  background-color: var(--white);
  bottom: 8px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 70px;
}
.contact-info-content .info-address .title {
  color: var(--white);
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.44px;
  line-height: 1.1;
  margin-bottom: 19px;
}
@media only screen and (max-width: 1199px) {
  .contact-info-content .info-address .title {
    font-size: 30px;
  }
}
.contact-info-content .info-address .title span {
  color: #c8eaea;
  font-weight: 300;
}
.contact-info-content .info-address p {
  color: var(--white);
  font-size: 16px;
  letter-spacing: -0.15px;
  line-height: 24px;
  margin-bottom: 36px;
}
@media only screen and (max-width: 1199px) {
  .contact-info-content .info-address p {
    font-size: 15px;
  }
}
.contact-info-content .info-address a {
  color: var(--white);
  font-size: 16px;
  letter-spacing: 0;
}
.contact-info-content .info-address a span {
  color: #99cccc;
}
.contact-info-content .brand-office {
  margin-top: 19px;
  max-width: 430px;
}
@media only screen and (max-width: 767px) {
  .contact-info-content .brand-office {
    max-width: none;
  }
}
.contact-info-content .brand-office .info-tem {
  letter-spacing: -0.15px;
  margin-bottom: 42px;
}
.contact-info-content .brand-office .info-tem h6 {
  color: #99cccc;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 9px;
}
.contact-info-content .brand-office .info-tem p {
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.contact-info-content .brand-office .info-tem.style-two {
  margin-bottom: 50px;
}
.contact-info-content .brand-office .info-tem.style-two h6 {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.contact-info-content .brand-office .info-tem.style-two p {
  font-size: 48px;
  line-height: 1;
}
@media only screen and (max-width: 1199px) {
  .contact-info-content .brand-office .info-tem.style-two p {
    font-size: 36px;
  }
}
@media only screen and (max-width: 479.98px) {
  .contact-info-content .brand-office .info-tem.style-two p {
    font-size: 30px;
  }
}
.contact-info-content .brand-office .info-tem:last-child h6 {
  margin-bottom: 10px;
}

.contact-form .section-title {
  margin-bottom: 70px;
}
@media only screen and (max-width: 1199px) {
  .contact-form .section-title {
    margin-bottom: 60px;
  }
}
.contact-form .contact-form-wrapper {
  background-color: var(--white);
  padding: 45px 50px 61px;
}
@media only screen and (max-width: 479.98px) {
  .contact-form .contact-form-wrapper {
    padding: 35px 25px 45px;
  }
}

.contact-map-area {
  background-color: #ddd;
  height: 636px;
}
@media only screen and (max-width: 1399px) {
  .contact-map-area {
    height: 420px;
  }
}
@media only screen and (max-width: 1199px) {
  .contact-map-area {
    height: 380px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-map-area {
    height: 320px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-map-area {
    height: 300px;
  }
}
.contact-map-area iframe {
  height: 100%;
  width: 100%;
}